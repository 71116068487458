import React from "react";
import { Helmet } from "react-helmet";
import SkewBg from "../../components/SkewBg/SkewBg";
// import Email from "../../components/Shared/Email";
import FacilityForm from "../../FacilityForms.pdf";
// import formImg from "../../assets/images/formsImg.png";
const classes = require('./MedicalForms.module.scss');

const skewTitle = (<h1>Medical Facility Forms</h1>);
const skewSub = (<h2>Medical Air Rescue Company</h2>);

const contact = () => {
  return (
    <div className={classes.MedicalForms}>
      <Helmet>
        <title>Medical Facility Forms for Healthcare Partners | Medical Air Rescue Company</title>
        <meta name="description" content="Contact Medical Air Rescue Company if you need more information, would like ​to schedule a flight, or if you are interested in becoming one of our valued partners." />
      </Helmet>
      
      <SkewBg title={skewTitle} subtitle={skewSub} />

      <div className={classes.mfBody}>
        <div className={classes.textCenter}>
          <div className={classes.med2Def}>
            <p className={classes.smSmall}>Our mission is to serve our communities with friendly, efficient, and trustworthy air medical care, and we have been dedicated to that mission for almost 40 years. 
              We appreciate being a trusted partner in providing excellent patient care and service to your medical facility. </p>
          </div>

          <div className={classes.formSec}>
            <div className={classes.insideNrw}>
              <p className={classes.strong}>  
                Follow the link below to view and print our patient transport documents.
                Preparing these forms in advance will help streamline the transport process. 
              </p>
              <a href={FacilityForm} target="_blank" rel="noreferrer" className={classes.marcLink}>View Documents</a>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
export default contact;
import React from "react";
import { Link } from "react-router-dom";
import Social from "../Social/Social";
import Phone from "../Shared/Phone";
import Email from "../Shared/Email";
import Drawer from "../Header/Drawer";
// import logo from "../../assets/images/MARCLogo.png";
import logo from "../../assets/images/MARCwhite.png";
const classes = require('./Footer.module.scss');

const footer = () => {
  return (
    <footer className={classes.Footer}>
      <div className={classes.fWrap1b}>
        <div className={classes.FooterBg}>
          <div className={classes.insideNrw}>
            <div className={classes.topLinks}>
              <div className={classes.mobNav}><Drawer /></div>     
              <div className={classes.tlCol}>
                <div className={classes.tlHd}><Link to="/">Home</Link></div>
                <div className={classes.clearfix} /><br/>
                <div className={classes.tlHd}><Link to="/fleet">Fleet</Link></div>
                <div className={classes.clearfix} /><br/>
                <div className={classes.tlHd}><Link to="/employment">Employment</Link></div>
              </div>

              <div className={classes.tlCol}>
                <div className={classes.tlHd}>Company</div>
                <div className={classes.tlList}>
                  <Link to="/about">About</Link>
                  <Link to="/leadership">Leadership</Link>
                  <Link to="/marc-news">In the News</Link>
                  <Link to="/employee-links">Employee Links</Link>
                  <Link to="/medical-forms" className={classes.long}>Medical Facility Forms</Link>
                </div>
              </div>
              
              <div className={classes.tlCol}>
                <div className={classes.tlHd}>Bases</div>
                <div className={classes.tlList}>
                  <Link to="/rapid-city-south-dakota">Rapid City, SD</Link>
                  <Link to="/valentine-nebraska">Valentine, NE</Link>
                  <Link to="/mckinney-texas">McKinney, TX</Link>
                  <Link to="/hugo-oklahoma">Hugo, OK</Link>
                </div>
              </div>
              
              <div className={classes.tlCol}>
                <div className={classes.tlHd}>Contact</div>
                <div className={classes.tlList}>
                  <Link to="/contact" className={classes.noOg}>Contact Page</Link>
                  <Link to="/feedback" className={classes.noOg}>Feedback</Link>
                  <Phone where="footer" addText="Dispatch: " />
                  <Email where="footer"/>
                  <button type="button" data-uk-toggle="target: #schedule-flight-modal" className={classes.marcLinkSm}>Schedule a Flight</button>
                </div>
              </div>
            </div>
            
            <div className={classes.socialRow}>
              <Social where="footer" />
            </div>
          </div>
        </div>

        <div className={classes.FooterBtm}>
          <div className={classes.fbLogo}><img src={logo} alt="Medical Air Rescue Company Logo" className={[classes.imgResponsive, classes.ftLogo].join(' ')} /></div>
          <div className={classes.copyHs2}>
          <p className={[classes.copy, classes.left].join(' ')}>© {(new Date().getFullYear())} Medical Air Rescue Company. All rights reserved. <br/>Design 
          by <a href="http://hsdesignhouse.com" target="_blank" rel="noopener noreferrer">High Seas Design House.</a></p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default footer;






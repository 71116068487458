import React from "react";
import { Helmet } from "react-helmet";
import SkewBg from "../../components/SkewBg/SkewBg";
import bgImg from "../../assets/images/hangarJet.jpg";
import bgImgM from "../../assets/images/hangarJetMobile.jpg";
import heart from "../../assets/images/MARCsymbol.png";
import png from "../../assets/images/fleetSolo2.png";
const classes = require('./Fleet.module.scss');
const skewTitle = (<h1>King-Air C90<br/> Fixed-Wing Fleet</h1>);
const skewSub = (<h2>The MARC Fleet</h2>);


const fleet = () => {
  return (
    <div className={classes.FleetPage}>
      <Helmet>
        <title>Fleet Advantages of Medical Air Rescue Company</title>
        <meta name="description" content="The MARC fleet is made up of King-Air C90 aircraft, the benefits of which have contributed to our long history of safety and dependability in emergency medical air rescue." />
      </Helmet>

      <SkewBg title={skewTitle} stackSub={skewSub} addClass="SkewStack" />

      <div className={[classes.StackLayout, classes.Fleet].join(' ')}>
        <div className={[classes.scRow, classes.scStory].join(' ')}>
          <div className={[classes.med2Def, classes.storyWrap].join(' ')}>
            <div className={classes.storyText}>
              <div className={classes.textbox}>
                <p className={classes.h3}>Our impressive fleet is made up of <b>King-Air C90 aircraft, </b> the amazing benefits of which have 
                helped contribute to our long history of safety and dependability in emergency medical air rescue.</p>
              </div>
            </div>
            
            <div className={classes.imgBar}>
              <img src={bgImg} alt="Medical Air Rescue Company King-Air C90 aircraft in hangar" className={[classes.storyImage, classes.fw].join(' ')}/>
              <img src={bgImgM} alt="MARC maintenance washing King-Air C90 aircraft" className={[classes.storyImage, classes.siMobile].join(' ')}/>
            </div>
          </div>
        </div>

        <div className={[classes.scRow, classes.scBg, classes.scGrid, classes.g1].join(' ')}>
          <div className={[classes.medDef, classes.cl].join(' ')}>
            <div className={classes.box}>
              <p className={classes.h4}>King-Air Advantages</p>
              {/* <p>Known worldwide as the most versatile and rugged turbo-prop ever built.</p> */}
              <p>Capable of cruising at altitudes of 30,000 feet -- above most weather and turbulence. 
              This allows us to operate at <b>maximum air speed, and in diverse weather conditions.</b></p>
              {/* 240 knots (275 mph) */}
              <p>Longer distance capabilities allow us to fly <b>anywhere in the continental U.S. </b>
              -- which means a faster arrival to destinations like specialty hospitals and burn/trauma centers.
              </p>
            </div>
          </div>
        </div>

        <div className={[classes.scRow, classes.scBg, classes.scGrid, classes.g2].join(' ')}>
          <div className={[classes.medDef, classes.textWrap].join(' ')}>
            <div className={[classes.item, classes.i1].join(' ')}>
              <p>All cabins are pressurized and climate controlled, to provide maximum comfort for the patient.</p>
            </div>
            
            <div className={[classes.item, classes.i2].join(' ')}>
              <p>The roomy interior allows for a <b>family member to travel with every patient.</b> So patients don't have to fly or arrive at their destination alone.</p>
            </div>

            <div className={[classes.item, classes.i3].join(' ')}>
              <p>It also accommodates <b>2 critical care providers to be with a patient at all times, significantly increasing medical attention and patient care.</b></p>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.FleetDets2b}>
        <div className={classes.inside}>
          <div className={classes.fleetLax} data-uk-parallax="bgy: 250; media:1025;">
            <div className={classes.floatImg}><img src={png} alt="MARC logo symbol" /></div>
            <p className={classes.h4}><span>MARC Advantages</span></p>  

            <div className={[classes.box, classes.box1].join(' ')}>
              <img src={heart} alt="" /><div className={classes.clearfix} />
              <p className={classes.awk}><span>Incredible Safety Record</span><br/>
                Almost 40 years of service, and we still have a flawless safety record.
              </p>

              <div className={classes.spacer2} />
              <img src={heart} alt="" /><div className={classes.clearfix} />
              <p><span>Real-Time Provider Communication</span><br/>
                We offer a provider-focused communication center, which allows for real-time discussions with medical providers.
                This center is fully staffed with trained personnel, who specialize in coordinating and expediting the referral process, to ensure that the patient's needs 
                can be met with timely precision.
              </p>
            </div>

            <div className={[classes.box, classes.box2].join(' ')}>
              <img src={heart} alt="" /><div className={classes.clearfix} />
              <p><span>State-of-the-art Technology</span><br/>
                Our medical team performs extensive, in-flight patient monitoring that requires the latest in medical equipment and life-saving technology.</p>
              <p className={classes.nobg}>Our flights are equipped with: video laryngoscopy, hands-free pacing &amp; defibrillation, 
                ECG waveforms, pulse oximetry, end-tidal CO2, non-invasive blood pressure (NIBP), multi-chamber infusion pumps, autopulse resuscitation, and impact ventilators (Zoll). 
              </p>

              <div className={classes.mobileBox}>
                <div className={classes.spacer2} />
                <img src={heart} alt="" /><div className={classes.clearfix} />
                <p><span>Premier Pilot Team</span><br/>
                  Our exceptionally skilled pilot team has recorded over 60,000 combined flight hours.
                </p>
              </div>
            </div>

            <div className={[classes.box, classes.box3].join(' ')}>
              <div className={classes.fwBox}>
                <img src={heart} alt="" /><div className={classes.clearfix} />
                <p><span>Premier Pilot Team</span><br/>
                  Our exceptionally skilled pilot team<br className={classes.twHide}/> has recorded over 60,000<br className={classes.twHide}/> combined flight hours.
                </p>
                <div className={classes.spacer2} />
              </div>

              <img src={heart} alt="" /><div className={classes.clearfix} />
              <p><span>On-site maintenance</span><br/>
              While most of our competitors handle their routine maintenance off-site, we have developed our own comprehensive maintenance department right inside our hangars --
              with multiple employees solely dedicated to the upkeep of our aircraft.
              </p>
            </div>        
          </div>

        </div>
      </div>    
    </div>
  );
}
export default fleet;
import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import Phone from "../../components/Shared/Phone"
import Email from "../../components/Shared/Email"
import SkewPlax from "../../components/SkewPlax/SkewPlax"
import SkewBg from "../../components/SkewBg/SkewBg"
import naamta from "../../assets/images/naamta.png"
import image1 from "../../assets/images/marc21.jpg"
import image2 from "../../assets/images/marc2b.jpg"
import image3 from "../../assets/images/marc14.jpg"
import heartw from "../../assets/images/MARCsymbolW.png"
const classes = require('./Home.module.scss')
const rotator = (
  <p className={classes.rotator}>
    Focused On <br/> 
    <span className={[classes.rwWords, classes.rwWords2].join(' ')}>
      <span className={classes.one}>Patients. </span>
      <span className={classes.two}>Community. </span>
      <span className={classes.three}>Safety. </span>
      <span className={classes.four}>Partners. </span>
    </span>
  </p>
)
const plaxContent = (
  <div>
    <h1>Speed, Safety, &amp; Medical Care<br/>Like Your Life Depends On It</h1>
    <h2>Medical Air Rescue Company</h2>    
    <div className={classes.clearfix}/>
  </div>
)
const extras =(
    <div className={classes.cta}>
      <p>Dispatch: <Phone where="baseHeader" /> </p>
      <p><Email where="baseHeader" /></p>
    </div>
)
const quote = (
  <div className={[classes.quoteSlides].join(' ')} tabIndex="-1" data-uk-slider="autoplay:true;autoplay-interval:5000;">
    <div className="uk-position-relative">
      <h3>24/7 Immediate Response<br/>Throughout the Continental U.S.</h3>
      <img src={heartw} alt="Medical Air Rescue Company, logo symbol" className={classes.symbol} />
      <div className="uk-slider-container uk-light">
        <ul className={[classes.slItems, "uk-slider-items"].join(' ')}>
          <li>
            <p>“They are a great crew! We are lucky to have amazing people provide care and transportation for our patients. Thank you! Job well done!”
            <br/><span>-Ashley</span></p>
          </li>

          <li>
            <p>“Nick does a great job with dispatch. Effective and to the point, appreciate talking to him. 
            Flight team is amazing, great crew, friendly, always know my patients are in great hands!”
            <br/><span>-Sybil</span></p>
          </li>

          <li><p>“MARC employees are top notch. They have an attitude of servitude. Took amazing care of my aunt!!”<br/><span>-Patrice</span></p></li>
          <li><p>“Awesome flight crew, very informative and keep in communications on flight and ETA.”<br/><span>-Jeri</span></p></li>
        </ul>
      </div>
    </div>
    <button className={[classes.arrowRight, "uk-position-center-right"].join(' ')} data-uk-slidenav-next uk-slider-item="next"></button>
  </div>
)
const tagline = (<h3>When Seconds Count,<br/> Count on MARC</h3>)

const home = () => {
  return (
    <div className={classes.HomePage}>
      <Helmet>
        <title>Safe, Reliable Emergency Medical Air Transport in the Continental U.S.</title>
        <meta name="description" content="Medical Air Rescue Company provides efficient, reliable emergency air medical transport throughout the continental U.S." />
      </Helmet>

      <SkewPlax content={plaxContent} spClass="tall" addClass="Home" wrap="med2Def" extras={extras} />

      <div className={[classes.HomeSec, classes.sec0, classes.red].join(' ')}>
        <div className={[classes.medDef, classes.inline].join(' ')}>
          <div className={classes.skewWrap}>
            <div className={classes.left}>{rotator}</div>

            <div className={classes.right}>
              <p>We provide <b>efficient and reliable emergency air medical transport</b> for critically ill and 
              injured patients <b>throughout the continental U.S. </b> 
              We have been a trusted provider of medical care for almost <b>40 years.</b>
              </p>
            </div>

            <div className={classes.links}>
              <Link to="/about" className={classes.marcLinkRev}>Learn More</Link>
              <Link to="/contact" className={classes.marcLinkRev}>Contact Us</Link>
            </div>
          </div>
        </div>
      </div>
   
      <div className={[classes.HomeBeneBoxes].join(' ')}>
        <div className={classes.titleBox}>
          <div className={classes.med3Def}>
            <img src={naamta} alt="NAAMTA logo - National Accreditation Alliance Medical Transport Applications" className={classes.namLogo} />
            <p className={[classes.secTitle].join(' ')}>What Sets Us Apart?</p>{/* MARC */}
            <p className={[classes.subTitle].join(' ')}>Fully staffed 24/7 &middot; Immediate Response</p>
            <p className={classes.bmargin0}>
              We are an award-winning, family owned &amp; operated business that strives to deliver the best patient and provider care in the medical 
              air transport industry.
            </p>
          </div>
        </div>

        <div className={[classes.medDef, classes.shWrap].join(' ')}>
          <div className={classes.hbBody}>        
            <ul data-uk-accordion="collapsible: false">
              <li className={[classes.item, classes.row2, classes.left].join(' ')}>
                <button className={[classes.accordTitle, "uk-accordion-title"].join(' ')} href="#"><p className={classes.hi}><span>Impeccable</span> Safety</p></button>
                <div className={[classes.accordContent, "uk-accordion-content"].join(' ')}>
                  <p>
                  Our safety practices are strictly enforced from the ground up.
                  Our team is always alert, prepared, and professional, and we are constantly working to improve on our excellence in every department.
                  Click the link below to learn more about our commitment to safety.
                  </p>
                  <Link to={{pathname:"/about#advantages", activeTab: "marc"}} className={classes.marcLinkSm}>Learn More</Link>
                </div>
              </li>
              
              <li className={[classes.item, classes.row2, classes.right].join(' ')}>
                <button className={[classes.accordTitle, "uk-accordion-title"].join(' ')} href="#"><p className={classes.hi}><span>Intensive</span> Training Program</p></button>
                <div className={[classes.accordContent, "uk-accordion-content"].join(' ')}>
                  <p>We have the most demanding training program in the industry -- which means that
                    our team is made up of the most talented and experienced clinicians in the field today. Follow the link for 
                    more on how we provide the best of the best in critcal care.
                  </p>

                  <Link to={{pathname:"/about#advantages", activeTab: "medical"}} className={classes.marcLinkSm}> Learn More</Link>
                </div>
              </li>
              
              <li className={[classes.item, classes.row3, classes.plus].join(' ')}>
                <button className={[classes.accordTitle, "uk-accordion-title"].join(' ')} href="#"><p className={classes.hi}><span>King Air</span> C90 Fleet</p></button>
                <div className={[classes.accordContent, "uk-accordion-content"].join(' ')}>
                  <p>The King Air C90 aircraft is known for its safety and reliability -- just like us!
                    Our fleet can cruise at higher altitudes and speeds, meaning faster flights when you need them most. 
                    Not to mention our extensive in-flight patient monitoring, and enough room for a family member. So patients don't have to travel or arrive at their destination alone!       
                  </p>
                  <Link to="/fleet" className={classes.marcLinkSm}>Learn More</Link>
                </div>
              </li>

              <li className={[classes.item, classes.row3, classes.left].join(' ')}>
                <button className={[classes.accordTitle, "uk-accordion-title"].join(' ')} href="#"><p className={classes.hi}><span>Community</span> Driven</p></button>
                <div className={[classes.accordContent, "uk-accordion-content"].join(' ')}>
                  <p>Our entire organization truly wants to be a part of the communities we serve. 
                    We devote ourselves to giving back locally through education, tailored services, and philanthropy.
                  </p>              
                  <Link to={{pathname:"/about#advantages", activeTab: "partner"}} className={classes.marcLinkSm}>Learn More</Link>
                </div>
              </li>
    
              <li className={[classes.item, classes.row3, classes.right].join(' ')}>
                <button className={[classes.accordTitle, "uk-accordion-title"].join(' ')} href="#"><p className={classes.hi}><span>No Membership</span> Required</p></button>
                <div className={[classes.accordContent, "uk-accordion-content"].join(' ')}>
                  <p>We don't require membership for our services. Just teamwork. 
                    We are covered by most health insurance companies, and we <b>do not bill the patient beyond insurance coverage.</b>
                  </p>
                  <Link to={{pathname:"/about#advantages", activeTab: "marc"}} className={classes.marcLinkSm}>Learn More</Link>
                </div>
              </li>
            </ul>
          </div>

        </div>
      </div>

      <SkewPlax content={quote} addClass="MidHome" wrap="insideNrw" />
      <SkewBg text={tagline} skewStyle="SkewBanner" addClass="homeTag" wrap="custom" />

      <div className={[classes.homeNews].join(' ')}>
        <div className={classes.insideAlt}>
          <div className={classes.htBody}>

            <div className={[classes.newsItem, classes.item1].join(' ')}>
              <div className={classes.textBox}>
                <Link to="/Medical-Air-Rescue-Honored" className={classes.niLink}>
                  <p>Medical Air Rescue Honored for Outstanding Service</p>
                  <span>Read More</span> <span uk-icon="icon: arrow-right"></span>
                </Link>
              </div>
              <img src={image1} alt="Medical Air Rescue Company Rapid City crew, preparing critical patient for air medical transport" className={classes.img1} />
            </div>

            <div className={[classes.newsItem, classes.item2].join(' ')}>
              <div className={[classes.textBox, classes.longTitle].join(' ')}>
                <Link to="/MARC-Summer-Nights" className={classes.niLink}>
                  <p className={classes.longTitle}>Medical Air Rescue Celebrates First Responders at Rapid City Summer Nights</p>
                  <span>Read More</span> <span uk-icon="icon: arrow-right"></span>
                </Link>
              </div>
              <img src={image2} alt="Medical Air Rescue Company nurses and paramedics providing immediate care for critically ill patient" className={classes.img2} />
            </div>

            <div className={[classes.newsItem, classes.item3].join(' ')}>
              <div className={classes.textBox}>
                <p>Follow the link below to check out more of our community outreach initiatives.</p>
                <div className={classes.spacer1} />
                <Link to="/marc-news" className={classes.marcLinkRev}>See More <span uk-icon="icon: arrow-right"></span></Link>
              </div>
              <img src={image3} alt="MARC crew providing efficient air medical transport within their community" className={classes.img2} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default home
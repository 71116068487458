import React from "react";
const defaultH1 = (<h1>Reliable Healthcare <br/>When it Matters Most</h1>);

const welcome = (props) => {
  return (
    <div>
      {props.add ? props.add : [defaultH1]}
      <p>
        We have been a trusted provider of first-class emergency medical services for nearly 40 years. 
        Our mission is to serve our communities with friendly, efficient, and trustworthy air medical care.
      </p>
    </div>
  );
}
export default welcome;
import React from "react";
import { Helmet } from "react-helmet";
import classes from './Employment.module.scss';
import SkewBg from "../../components/SkewBg/SkewBg";
import stackImg1 from "../../assets/images/employmentImage1.jpg";
import foam from "../../assets/images/foamfrat.png";

const skewTitle = (<h1>Become A Part Of <br/>Something Life-Changing</h1>);
const skewSub = (<h2>Join the MARC Family</h2>);
const instr = ( <span className={classes.addin}>Select one of the categories below for more details.</span>);

const employment = () => {
  return (
    <div className={classes.EmploymentPage}>
      <Helmet>
        <title>Employment at Medical Air Rescue Company</title>
        <meta name="description" content="Medical Air Rescue Company is always looking for dedicated, driven professionals, to assist us in providing the extraordinary medical care on which we've built our reputation." />
      </Helmet>

      <SkewBg title={skewTitle} subtitle={skewSub} />

      <div className={[classes.PageIntro, classes.textCenter, classes.Staff].join(' ')}>
        <div className={[classes.medDef, classes.class2].join(' ')}>
          <p>We are always on the lookout for <span>dedicated, driven professionals</span> with a <span>passion for serving and saving, </span>
          to join our team, and assist us in providing the <span>extraordinary medical care</span> on which we've built <span>our reputation. </span>
          Working with us means working alongside some of the <span>most experienced, talented, and rigorously trained experts</span> in the industry.
          Follow the link below to <span>view/apply for available positions</span> within our organization.
          </p>
          <div className={classes.btnRow}>
            <a href="https://www.indeed.com/cmp/Medical-Air-Rescue-Co./jobs" target="_blank" rel="noreferrer" className={classes.marcLink}>Job Listings</a>
            <a href="https://form.jotform.com/jsul/marc-job-application" target="_blank" rel="noreferrer" className={classes.marcLink}>Apply Online</a>
          </div>          
        </div>
      </div>

      <div className={[classes.StackLayout, classes.Staff, classes.redTabs].join(' ')}>
        <div className={[classes.scRow, classes.scStory].join(' ')}>
          <div className={[classes.medDef, classes.storyWrap].join(' ')}>
            <div className={classes.storyText}>
              <p className={classes.title}>More Than "Just a Job"</p>
              <div className={classes.textbox}>
                <p>
                  Medical Air Rescue Company is family owned and operated. When you come to work with us, you become part of that family. 
                  We function as one team with one goal: to provide safe, comfortable, and worry-free medical transportation, in the air and on the ground.
                </p>  
          
                <p className={classes.bold}>Every member of our medical team must complete our extensive, 672-hour training program.
                This program includes comprehensive oversight by our Medical Director for operations, training, and education, and is unsurpassed in the 
                medical air transport industry. {instr}
                </p>
              </div>
            </div>
            
            <div className={classes.imgBar}>
              <img src={stackImg1} alt="MARC crew during extensive training for air medical transport" className={classes.storyImage}/>
            </div>
          </div>
        </div>

        <div className={[classes.employTabs].join(' ')}>
          <div className={classes.size0wrap}>
            <p className={classes.mobInstr}>{instr}</p>
            <ul className={[classes.tabNav, classes.class2].join(' ')} data-color="redTabs" data-uk-tab="connect: #employmentInfo; animation: uk-animation-fade">
              <li className={"uk-active"}><button className={classes.subject} href="#">Medical</button></li>
              <li><button className={classes.subject} href="#">Pilots</button></li>
            </ul>
          </div>

          <div className={[classes.tabContentWrap, classes.class2].join(' ')}>
            <div className={classes.size1wrap}>
              <ul id="employmentInfo" className={[classes.tabContent, "uk-switcher"].join(' ')}>
                <li className={classes.medTab}>
                  <div className={[classes.scRow, classes.minReq].join(' ')}>
                    <p className={classes.h4}>Medical Team Required Minimums</p>
                    <div className={classes.item}>
                      <p className={classes.subject}>Registered Nurses</p>
                      <p><b>5 Years</b><br/> Critical Care/Emergency Medicine</p>
                    </div>
                    <div className={classes.item}>
                      <p className={classes.subject}>Paramedics</p>
                      <p><b>5-7 Years</b><br/> Urban 911 and/or Critical Care Transport</p>
                    </div>
                  </div>

                  <div className={[classes.scRow, classes.training, classes.xdots].join(' ')}>
                    <p className={classes.h4}>Medical Training Program</p>
                    <div className={[classes.item, classes.item1].join(' ')}>
                      <p><b>Comprehensive In-House Training</b><br/> <span className={classes.em}>MARC Critical Care Flight Academy</span></p>
                      <p><b>Air Medical Resource Management</b></p>
                      <p><b>Live Patient Experiences</b><br/> <span className={classes.em}>Intubation partnership with local <br className={classes.mobBr}/>medical facilities</span></p>
                      <p><b>High Fidelity Simulation Experiences</b><br/> <span className={classes.em}>Partnership with Western Dakota Tech for medical simulation experiences</span></p>
                      <p><b>Educational Experiences with Neopediatric Heart &amp; Vascular Critical Care Team</b></p>
                    </div>

                    <div className={[classes.item, classes.item2].join(' ')}>
                      <p><b>Physician Led Education with Hands-on Skills Laboratory</b></p>
                      <p><b>FOAMfrat</b><br/> <span className={classes.em}>Online critical care &amp; advanced certification 
                      prep course provided for all medical crew members. 
                      <span className={classes.buff4}> Select one of the logos below to learn more about each program.</span></span></p>
                      <a href="http://www.foamfrat.com/" target="_blank" rel="noreferrer" className={[classes.logoRow, classes.logo1].join(' ')}><img src={foam} alt="FOAM frat logo" /></a>
                    </div>
                    <div className={classes.clearfix}/>
                  </div>
                </li>

                <li className={classes.pilotTab}>
                  <div className={[classes.scRow, classes.minReq].join(' ')}>
                    <p className={classes.h4}>Pilot Team Required Minimums</p>
                    <div className={classes.item}><p><b>2500 Hours</b> Total Flight Time</p></div>
                    <div className={classes.item}><p><b>1000 Hours</b> Multi-Engine Time</p></div>
                    <div className={classes.item}><p>Class 2 Medical Certificate</p></div>
                    <div className={classes.item}><p>Strong Single-Pilot Instrument Skills</p></div>
                  </div>

                  <div className={[classes.scRow, classes.training].join(' ')}>
                    <p className={classes.h4}>Pilot Training Program</p>

                    <div className={[classes.item, classes.itema].join(' ')}>
                      <p><b>5-Day Initial King Air Training</b></p>
                      <p><b>5-Day In-House Academic &amp; Flight Training</b></p>
                    </div>

                    <div className={[classes.item, classes.itemb].join(' ')}>
                      <p><b>2-Day Annual Recurrent Training</b></p>
                      <p><b>Quarterly Part 135 Recurrent Academics</b></p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default employment;
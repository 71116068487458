import React from "react";
import { Link } from "react-router-dom";
import naamta from "../../assets/images/naamtaG.png";
// import naamtaW from "../../assets/images/naamta.png";
import classes from "./DetailTabs.module.scss";
import award from "../../assets/images/alfAward.jpg";
import awardMobile from "../../assets/images/alfAwardMobile.jpg";


const detailTabs = (props) => {
  return (
    <div className={[classes.dtWrap, classes[props.where]].join(' ')}>
    <div className={[classes.StackLayout, classes.detailTabs, classes[props.where], classes[props.addClass], [props.classless]].join(' ')} data-uk-grid>
      <div className={[classes.tabWrap, classes[props.wrapClass]].join(' ')}>
        {/* {props.headline ? <p className={classes.headline}>{props.headline}</p> : null} */}
        <div className={[classes.inside, classes.tnInside].join(' ')}>
          <ul className={[classes.tabNav, classes.class2].join(' ')} data-uk-tab="connect: #marc-advantages; animation: uk-animation-fade">
            <li className={props.activeTab === "marc" ? "uk-active" : null}><button className={classes.subject} href="#"><b>MARC</b><br/> <span>Advantage</span></button></li>
            <li className={props.activeTab === "partner" ? "uk-active" : null}><button className={classes.subject} href="#"><b>Partner</b><br/> <span>Advantage</span></button></li>
            <li className={props.activeTab === "medical" ? "uk-active" : null}><button className={classes.subject} href="#"><b>Medical</b><br/> <span>Advantage</span></button></li>
            <div className={classes.clearfix} />
            <li className={props.activeTab === "fleet" ? "uk-active" : null}><button className={classes.subject} href="#"><b>Fleet</b><br/> <span>Advantage</span></button></li>
            <li className={props.activeTab === "award" ? "uk-active" : null}><button className={classes.subject} href="#"><b>Awards &amp;</b><br/> <span>Achievements</span></button></li>
          </ul>
        </div>

        <div className={[classes.tabContent, classes.class2].join(' ')}>
          <div className={[classes.medDef, classes.tcWrap].join(' ')}>
            <ul id="marc-advantages" className={[classes.tabContent, "uk-switcher"].join(' ')}>
              <li className={classes.marcTab}>
                <div className={classes.tcRow}><div className={classes.medDef}>
                  {props.headline ? <p className={[classes.title, classes.bmargin0].join(' ')}>{props.headline}</p> : null}
                  <p className={[classes.pillars, classes.BaseHide].join(' ')}>Our Pillars:<br/> <span>Integrity &middot; Modesty &middot; Accountability &middot; Respect &middot; Compassion</span></p>
                  <p>We are family focused and community driven. Our success has come from doing things the right way - <span className={classes.buff}>for our partners and our patients. </span>
                  Call our communications center <span className={classes.buff}>24/7</span> to schedule transport, and speak with 
                  a <span className={classes.buff}>REAL person. EVERY time.</span>
                  </p>
                </div></div>

                <div className={[classes.eq, classes.left].join(' ')}>
                  <p className={classes.subject}>Safe &amp; Reliable</p>
                  <p>We are extremely proud of our <span className={classes.buff}>impeccable</span> safety record. Safety is our top priority. We are meticulous 
                  about every detail -- from the maintenance and cleanliness of our ambulances and the quality of our equipment,
                    to the knowledge and experience of our crew and medical staff. 
                  </p>
                  
                  <p className={classes.ilp}>We are NAAMTA accredited. Our team is determined to raise the bar for dependability in air medical transport.</p>
                  <img src={naamta} alt="NAAMTA logo - National Accreditation Alliance Medical Transport Applications" className={[classes.naamta1, classes.color].join(' ')} /><div className={classes.clearfix} />
                </div>

                <div className={[classes.eq, classes.right].join(' ')}>
                  <p className={classes.subject}>Training &amp; Experience</p>
                  <p>Our organization is made up of highly experienced EMTs, paramedics, registered nurses, and pilots, all rigorously trained in their fields of expertise.
                    Select the <span className={classes.buff}>"Medical Advantage"</span> tab above to learn more.
                  </p>
                  
                  <div className={classes.spacer1} />
                  <p className={classes.subject}>There for Your Patients</p>
                  <p>We want to help you help your patients. <span className={classes.buff}> We do NOT bill the patient beyond insurance coverage. </span>
                  We also allow 1 family member of the patient to ride along, so patients don't have to travel or arrive alone.
                  <a href="#patient-focused" className={classes.textLinkRed} data-uk-scroll> Click here</a> to learn more about our commitment to patients.</p>
                </div>
              </li>

              <li className={classes.partnerTab}>
                <div className={classes.tcRow}>
                  <p>We are dedicated to our communities, and we truly believe in giving back through education, tailored services, and philanthropic initiatives.

                  <Link to="/contact" className={classes.textLink}> Contact us</Link> for more partnership information. We would love to work with you!
                  </p>
                </div>

                <div className={[classes.small, classes.left].join(' ')}>
                  <p className={classes.subject}>Educational Classes</p>
                  <p>When you partner with us, we offer specialized education courses and classes for your medical staff. 
                  Classes included BLS, ACLS, PALS, PHTLS, and more. </p>
                  
                  <div className={classes.spacer1}/>
                  <p className={classes.subject}>Training Opportunities</p>
                  <p>Our experienced critical care clinicians will come on site to offer Code Blue training, 
                  trauma training, and other trainings tailored to your facility. 
                  </p>
                </div>
                
                <div className={[classes.large, classes.right].join(' ')}>
                  <p className={classes.subject}>Quality Assurance &amp; Improvement </p>
                  <p>
                  As part of our strict adherence to the standards of the air medical transport industry, we are dedicated to meeting your specific needs, and have QA/QI 
                  programs in place to ensure that your specific goals are consistently exceeded. 
                  </p>
                  
                  <div className={classes.spacer1}/>
                  <p className={classes.subject}>Feedback Mechanisms </p>
                  <p>We value each partnership, and believe that continuous evaluation of our procedures is key. We have developed feedback mechanisms that allow 
                    us to constantly monitor patient and facility satisfaction, so we can continue to be the premier air medical provider. 
                  </p>
                </div>
              </li>

              <li className={classes.medicalTab}>
                <div className={classes.tcRow}>
                  <p>We are committed to providing the best patient and provider care available in the medical air transport industry.
                    We are fully staffed 24/7 to allow for immediate response time. 
                  </p>
                </div>
                
                <div className={[classes.small, classes.left].join(' ')}>
                  {/* <p className={classes.subject}>Pioneers in the Industry </p>
                  <p>Our president co-invented the LifePort transport system that is now used world-wide in air medical transport.</p> */}
                  
                  {/* <div className={classes.spacer1}/> */}
                  <p className={classes.subject}>Expert Medical Crew</p>
                  <p>Our organization is made up of highly experienced critical care clinicians, consisting of certified, registered nurses and paramedic teams 
                    -- all of whom must undergo an intensive, <b>672-hour training program</b> that is unsurpassed in the industry. 
                  So you can feel confident that when you are treated by our team, you are truly getting the best of the best in all aspects of critcal care.</p>
                </div>
                
                <div className={[classes.large, classes.right].join(' ')}>
                  <p className={classes.subject}>Intensive Patient Monitoring </p>
                  <p>Our medical team performs extensive, in-flight patient monitoring that requires the most sophisticated avionics, and state-of-the-art medical equipment.
                  So all of our aircraft carry the latest in life-saving technology. 
                  </p>
                  
                  <p>We offer a provider-focused communication center, with trained personnel who specialize in 
                  coordinating, accommodating, and expediting the referral process. This ensures that patient and provider needs are met with timely precision. 
                  </p>
                </div>
              </li>

              <li className={classes.fleetTab}>
                <div className={classes.tcRow}>
                  <p>
                    Our fleet is comprised of King Air C90 aircraft, known worldwide as the most versatile and rugged turboprop ever built. 
                    Read through some of the biggest advantages below, or <Link to="/fleet" className={classes.textLink}>click here</Link> to learn more about our fleet.
                  </p>
                </div>

                <div className={[classes.small, classes.right].join(' ')}>
                  <p className={classes.subject}>Fast Response Times</p>
                  <p>Capable of cruising up to 30,000 feet, at speeds up to 240 knots (275 mph), which gets your patient to their destination quickly.</p>
                  
                  <div className={classes.spacer1}/>
                  <p className={classes.subject}>Longer Distances </p>
                  <p>These twin engine turboprops can fly anywhere in the continental United States.</p>
                  
                  <div className={classes.spacer1}/>
                  <p className={classes.subject}>Premier Pilot Team </p>
                  <p>Exceptionally skilled pilot team with over 60,000 combined flight hours.</p>
                </div>

                <div className={[classes.large, classes.left].join(' ')}>
                  <p className={classes.subject}>First Class Comfort </p>
                  <p>All cabins are pressurized and climate controlled, to provide maximum comfort for the patient.</p>
                    <ul className={classes.disc}>
                    <p>The roomy interior allows for:</p>
                    <li>A family member to travel with every patient.</li>
                    <li>Multi-position stretcher for patient comfort.</li>
                    <li>Maximum medical accessibility and continuous patient care.</li>
                    </ul>

                  <div className={classes.spacer1}/>
                  <p className={classes.subject}>Intensive Patient Monitoring </p>
                  <p>The spacious cabins also allow for two critical care providers with patient at all times, from bedside to bedside.</p>
                  <p>All flights are equipped with state of the art avionics, including the latest in life-saving technology, and 
                    WIFI capability -- which allows us to communicate with the provider, and coordinate/expedite the referral process.
                  </p>
                </div>                
              </li>
            
              <li className={classes.achieveTab}>
                <div className={classes.inside}>
                  <div className={[classes.eq, classes.left].join(' ')}>
                    <p className={classes.subject}>Pioneers in the Industry </p>
                    <p>Company President, Jerry Dale, co-invented the LifePort transport system that is now used world-wide in air medical transport.
                    </p>
                  
                    <div className={classes.spacer2} />
                    <p className={classes.subject}>Awards</p>
                    <p><b>2018 Fixed Wing Transports</b></p>

                    <p><b>A. L. Fountain Award (2021)</b><br/>
                      Presented in honor of all front line heroes in support of health during the COVID-19 pandemic. 
                    </p>
                  </div>
                  
                  <div className={[classes.eq, classes.right].join(' ')}>
                    <img src={award} alt="Medical Air Rescue Company accepting award for front line heroes during the COVID-19 pandemic" className={classes.imgFw} />
                    <img src={awardMobile} alt="Medical Air Rescue Company receiving award for service during COVID-19 pandemic" className={classes.imgMobile} />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      </div>
      <div className={classes.pillarsRow}><p className={classes.pillars}>MARC Pillars:<br/><span>Integrity &middot; Modesty &middot; Accountability <br/> Respect &middot; Compassion</span></p></div>
    </div>
  );
};
detailTabs.defaultProps = {
  activeTab: "marc"
};
export default detailTabs;
import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import { Link } from "react-router-dom";
const classes = require('./Header.module.scss');
const styles = require('../Footer/Footer.module.scss');

function TemporaryDrawer() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={[classes.list, classes.SideNavList].join(' ')}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <div className={classes.closeIcon}><ClearIcon /></div>
      <ul className={classes.SideNav}>
        <li className={classes.navItem}><Link to="/">Home</Link></li>
        {/* <li className={classes.navItemCat}>Company */}
          {/* <ul className={classes.SubNav}> */}
            <li className={classes.navItem}><Link to="/about">About</Link></li>
            <li className={classes.navItem}><Link to="/leadership">Leadership</Link></li>
            <li className={classes.navItem}><Link to="/medical-forms">Medical Facility Forms</Link></li>
            <li className={classes.navItem}><Link to="/marc-news">In the News</Link></li>
          {/* </ul> */}
        {/* </li> */}

        <li className={classes.navItem}><Link to="/fleet">Fleet</Link></li>
        <li className={classes.navItem}><Link to="/employment">Employment</Link></li>
        <li className={classes.navItem}><Link to="/contact">Contact</Link></li>
        <li className={classes.navItem}><Link to="/feedback">Feedback</Link></li>
        <li className={classes.navItemCat}>MARC Bases
          <ul className={classes.SubNav}>
            <li className={classes.navItem}><Link to="/rapid-city-south-dakota">Rapid City, SD</Link><br/><span className={classes.small}>Corporate Headquarters</span></li>
            <li className={classes.navItem}><Link to="/valentine-nebraska">Valentine, NE</Link></li>
            <li className={classes.navItem}><Link to="/mckinney-texas">McKinney, TX</Link></li>
            <li className={classes.navItem}><Link to="/hugo-oklahoma">Hugo, OK</Link></li>
          </ul>
        </li>
      </ul>
    </div>
  );

  return (
    <div className={[classes.NavDrawer, styles.ftDrawer].join(' ')}>
      <Button onClick={toggleDrawer('left', true)}>
        <div className={[classes.mobileNavBtn, styles.mobileNavBtn].join(' ')}>
          <p className={styles.drawerText}>Menu</p>
          <span className={styles.noFt} />
          <span className={styles.noFt} />
          <span className={styles.noFt} />
        </div>
      </Button>
     
      <Drawer anchor="left" open={state.left} onClose={toggleDrawer('left', false)} className={classes.cNavDrawer}>
        {sideList('left')}
      </Drawer>
    </div>
  );
}

export default TemporaryDrawer;
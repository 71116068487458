import React from "react";
import Phone from "../../components/Shared/Phone";
import Email from "../../components/Shared/Email";
const classes = require('./BaseInfo.module.scss');

const baseInfo = (props) => {
  return (
    <div id="base-contact" className={[classes.BaseInfo, classes[props.baseClass], classes[props.class2]].join(' ')}>
      <div className={[classes.medDef, classes.shWrap].join(' ')}>
        <div className={classes.shBody}>
          <div className={classes.content}>
            <div className={[classes.group, classes.g1].join(' ')}>
              <p className={classes.first}><b>Emergency Dispatch </b><br/><Phone where="baseInfo" /></p>
              <p><b>Email </b><br/><Email where="baseInfo" /></p>
              <p><b>Information</b><br/><a href="tel:+16053930300">(605) 393-0300</a><br/><span>Available During Office Hours<br/> Monday-Friday: 8am-5pm</span></p>
            </div>

            <div className={[classes.group, classes.g2].join(' ')}>
              <p><b>Admin Fax</b><br/><a href="tel:+16053930306">(605) 393-0306</a></p>
              {props.baseDetails}
            </div>
          </div>

          {props.image ? <div className={classes.right}><img src={props.image} alt={"Medical Air Rescue Company - " + props.base} className={classes.skewImg} /></div> : null }
        </div>
      </div>
      <div className={classes.clearfix}/>
    </div>
  );
}
baseInfo.defaultProps = {
  imageClass: "single"
//   stack: "default"
};
export default baseInfo;
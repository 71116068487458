import React from "react";
import { Helmet } from "react-helmet";
import SkewBg from "../../components/SkewBg/SkewBg";
import elImg1 from "../../assets/images/elVivid.png";
import elImg2 from "../../assets/images/elAladtec.png";
// import elImg3 from "../../assets/images/elTrio.png";
const classes = require('./EmployeeLinks.module.scss');
const skewTitle = (<h1>Employee Links</h1>);

const employeeLinks = () => {
  return (
    <div className={classes.EmployeeLinks}>
      <Helmet><title>Employee Links</title></Helmet>

      <SkewBg title={skewTitle} />
      <div className={classes.links}>
        <div className={[classes.insideNrw].join(' ')}>
          <div className={[classes.group, classes.first].join(' ')}>
            <p className={classes.cat}>Email, Files, &amp; Forms</p>
            <p><a href="https://outlook.office.com" target="_blank" rel="noreferrer">Email</a></p>
            <p><a href="https://daleaviation.sharepoint.com" target="_blank" rel="noreferrer">Sharepoint Shared Files</a></p>
            <p><a href="https://daleaviation-my.sharepoint.com" target="_blank" rel="noreferrer">Sharepoint Private Files</a></p>
            <p><a href="https://daleaviation.sharepoint.com/Forms" target="_blank" rel="noreferrer">Sharepoint Forms</a></p>
            {/* <div className={[classes.elImg, classes.img1].join(' ')}><img src={elImg3} alt="Employee links logo with link" /></div> */}
          </div>

          <div className={[classes.group, classes.two].join(' ')}>
            <p className={classes.cat}>Scheduling &amp; TimeClock</p>
            <p><a href="https://secure7.aladtec.com/medicalairrescue"  target="_blank" rel="noreferrer" className={classes.img2}><img src={elImg2} alt="MARC employee links - aladtec logo" /></a></p>
          </div>

          <div className={[classes.group, classes.last].join(' ')}>
            <p className={classes.cat}>Credentialing &amp; Training</p>
            <p><span>Contact Shawn Clark with any issues.</span></p>
            <p><a href="https://compliance.vividlms.com" target="_blank" rel="noreferrer" className={classes.img3}><img src={elImg1} alt="MARC employee links - Vivid logo" /></a></p>
          </div>
        </div>
      </div>    
    </div>
  );
}
export default employeeLinks;
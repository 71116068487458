import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SkewBg from "../../components/SkewBg/SkewBg";
// import png from "../../assets/images/fleetSolo2.png";
const classes = require('./Fallback.module.scss');
const skewTitle = (<h1>Medical Air<br/> Rescue Company</h1>);
// const skewSub = (<h2>The MARC Fleet</h2>);


const fallback = () => {
  return (
    <div className={classes.FallbackPage}>
      <Helmet>
        <title>Medical Air Rescue Company</title>
        <meta name="description" content="MARC provides emergency air medical transport throughout the continental U.S. We are committed to our partners, our patients, and our communities." />
      </Helmet>


      <SkewBg title={skewTitle}  />

      <div className={classes.fbWrap}>
        <div className={classes.medDef}>
          <p>Looks like you took a wrong turn.<br/> Try one of the links below to get back on track.</p>

          <ul className={classes.Links}>
            <li className={classes.item}><Link to="/" className={classes.marcLink}>Home</Link></li>
            <li className={classes.item}><button type="button" data-uk-toggle="target: #schedule-flight-modal" className={classes.marcLink}>Schedule a Flight</button></li>
            <li className={classes.item}><Link to="/about" className={classes.marcLink}>About Us</Link></li>
            <li className={classes.item}><Link to="/employment" className={classes.marcLink}>Employment</Link></li>
            <li className={classes.item}><Link to="/contact" className={classes.marcLink}>Contact</Link></li>
          </ul>

        </div>
      </div>

    </div>
  );
}
export default fallback;
import React from "react";
import { Helmet } from "react-helmet";
import SkewBg from "../../components/SkewBg/SkewBg";
import Email from "../../components/Shared/Email";
import ContactForm from "../../components/ContactForm/ContactForm";
import ContactImg from "../../assets/images/uniform.jpg"
const classes = require('./Contact.module.scss');

const skewTitle = (<h1>Get in Touch With Us</h1>);
const skewSub = (<h2>Medical Air Rescue Company</h2>);

const contact = () => {
  return (
    <div className={classes.Contact}>
      <Helmet>
        <title>Contact Medical Air Rescue Company</title>
        <meta name="description" content="Contact Medical Air Rescue Company if you need more information, would like ​to schedule a flight, or if you are interested in becoming one of our valued partners." />
      </Helmet>
      
      <SkewBg title={skewTitle} subtitle={skewSub} />

      <div className={classes.ContactBody}>
        <div className={classes.linkRow}>
          <div className={classes.med3Def}>
            <p>If you are interested in becoming one of our valued partners, or if you would like more information about our company,
              fill out the contact form below, and someone from our team will be in touch with you soon.
              You can also email us at: <Email where="textLink" />.
            </p>
          </div>
        </div>

        <div className={classes.medDef}>
          <img src={ContactImg} className={classes.formImg} alt="Medical Air Rescue Company transporting patient to emergency flight." />
          <div className={classes.ilForm}><ContactForm FormType="ContactPg" /></div>
        </div>
      </div>
    </div>
  );
}
export default contact;
import React from "react";
import { Helmet } from "react-helmet";
import SkewPlax from "../../components/SkewPlax/SkewPlax";
import SkewBg from "../../components/SkewBg/SkewBg";
import DetailTabs from "../../components/Stack/DetailTabs";
import BaseInfo from "../../components/BaseInfo/BaseInfo";
import hugoImage from "../../assets/images/hugo.jpg";
import Welcome from "./Welcome.js";
const classes = require('./Locations.module.scss');
const base = "Hugo, Oklahoma";
const baseShort = "Hugo, OK";
const plaxContent = (<Welcome />);
const skewSub = (<h3>{base}</h3>);
const skewTitle1 = (<h4>Medical Air<br/> Rescue Company</h4>);
const baseInfo = (
  <div>
    <p><b>Base Lead </b><br/>Shon Maloy, PMD</p>
    <p><b>Lead Pilot</b><br/>Derek "Levi" Leivestad</p>
    <div className={classes.clearfixBtn}/>
    <a 
      href="https://www.google.com/maps/place/Medical+Air+Rescue+Co+-+Hugo/@34.017884,-95.5434389,13z/data=!4m5!3m4!1s0xa876a84efee56acb:0x9a8e3e833169702d!8m2!3d34.017889!4d-95.5084194" 
      target="_blank" 
      rel="noreferrer"
      className={classes.marcLinkSm}>
      Leave a Review</a>
  </div>
);

const hugo = () => {
  return (
    <div className={[classes.LocationsPage, classes.Hugo].join(' ')}>
      <Helmet>
        <title>{baseShort} Base | Medical Air Rescue Company</title>
        <meta name="description" content="Medical Air Rescue Company provides safe, reliable medical air transport across the contenintal U.S. We also love serving our local community in Hugo, OK." />
      </Helmet>

      <SkewPlax content={plaxContent} spClass="tall" addClass="Bases" />
      <SkewBg skewStyle="BaseStack" title={skewTitle1} stackSub={skewSub} addClass="hugoStack" />

      <BaseInfo baseClass="Hugo" base={base} baseDetails={baseInfo} image={hugoImage} />
      <DetailTabs where="bases" classless="baseTabs" headline="One Life. One Mission. Every Time." />
    </div>
  );
}
export default hugo;
import React, {Component} from 'react';
import { Link } from "react-router-dom";
import Drawer from "./Drawer";
import Phone from "../Shared/Phone";
import Email from "../Shared/Email";
import logo from "../../assets/images/MARCLogo.png";
// import logoW from "../../assets/images/MARCwhite.png";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Collapse from '@material-ui/core/Collapse';
const classes = require('./Header.module.scss');
const dropIcon = (<span className={classes.parentIcon} data-uk-icon="icon: chevron-down;"></span>);

export function SimpleCollapse(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleChange = () => {
    setOpen((prev) => !prev);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const companyList = (
    <div>
      <li className={classes.dropLi} onClick={handleClose}><Link to="/about">About</Link></li>
      <li className={classes.dropLi} onClick={handleClose}><Link to="/leadership">Leadership</Link></li>
      <li className={classes.dropLi} onClick={handleClose}><Link to="/medical-forms">Medical Facility Forms</Link></li>
      <li className={classes.dropLi} onClick={handleClose}><Link to="/marc-news">In the News</Link></li>
    </div>
  );

  const baseList = (
    <div>
      <li className={classes.dropLi} onClick={handleClose}><Link to="/rapid-city-south-dakota">Rapid City, SD</Link> <br/><span className={classes.small}>Corporate Headquarters</span></li>
      <li className={classes.dropLi} onClick={handleClose}><Link to="/valentine-nebraska">Valentine, NE</Link></li>
      <li className={classes.dropLi} onClick={handleClose}><Link to="/mckinney-texas">McKinney, TX</Link></li>
      <li className={classes.dropLi} onClick={handleClose}><Link to="/hugo-oklahoma">Hugo, OK</Link></li>
    </div>
  );
  let listItems = "";
  props.parent === "Company" ? listItems = companyList : listItems = baseList;

  return (
    <li className={classes.navItem}>
      <Link to="#" ref={anchorRef} onClick={handleChange}>
        {props.parent} {dropIcon}
      </Link>

      <Collapse in={open} className={classes.dropItem} {...(open ? {} : { timeout:0 })}>
        <ClickAwayListener onClickAway={handleClose}>
          <div className={classes.menuDrop}>
            <div className={classes.mdWrap}>
              <div className={classes.inner}>
                <div className={classes.ndaContent}>
                  {/* <img src={dropImg1} alt="MARC ph" /> */}
                  <p className={classes.tagline}>When seconds count,<br/> count on MARC.</p>
                </div>
                <ul onKeyDown={handleListKeyDown}>
                  {listItems}
                </ul>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </Collapse>
    </li>
  );
}

class Header extends Component {
  render() {
    return (
      <header className={[classes.Header, classes.Header1, classes[this.props.HeaderType]].join(' ')}>
        <div className={classes.topbar}>
          <div className={classes.inside}>
            <div className={[classes.tbCol, classes.tbBtn, classes.tbcBtn].join(' ')}>
              <button type="button" data-uk-toggle="target: #schedule-flight-modal" className={classes.linktb}>Schedule a Flight</button>
              {/* <button type="button" data-uk-toggle="target: #schedule-flight-modal" className={classes.marcLinkSm}>Schedule a Flight</button> */}
            </div>
            <div className={[classes.tbCol, classes.tbPhone, classes.tbcPhone].join(' ')}>Dispatch: <Phone where="topbar"/></div>
            <div className={[classes.tbCol, classes.tbEmail, classes.tbcEmail].join(' ')}><Email where="topbar"/></div>
          </div>
        </div>

        <div className={[classes.medDef, classes.hdWrap].join(' ')}>
          <nav className={[classes.navMm, "uk-navbar-container"].join(' ')} data-uk-navbar>
            <div className={[classes.hdCol, classes.colLogo].join(' ')}><Link to="/" className={[classes.logoMain, classes.logoA].join(' ')}><img src={logo} alt="Medical Air Rescue Company Logo" className={[classes.imgResponsive, classes.ilLogo].join(' ')} /></Link></div>
            
            <ul className={[classes.hdCol, classes.col1].join(' ')}>
              <SimpleCollapse parent="Company" />
              <SimpleCollapse parent="Bases" />
              <li className={classes.navItem}><Link to="/fleet">Fleet</Link></li>
            </ul>
            
            <ul className={[classes.hdCol, classes.col2].join(' ')}>
              <li className={classes.navItem}><Link to="/employment">Employment</Link></li>
              <li className={classes.navItem}><Link to="/contact">Contact</Link></li>
              <li className={classes.navItem}><Link to="/feedback">Feedback</Link></li>
            </ul>            
            <div className={classes.mobNav}><Drawer /></div>     
          </nav>        
        </div>
      </header>
    );
  }
}

export default Header;



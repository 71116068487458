import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SkewPlax from "../../components/SkewPlax/SkewPlax";
import DetailTabs from "../../components/Stack/DetailTabs";
import BaseMapLg from "../../components/MapShow/MapShow";
// import BaseMapSm from "../../components/BaseMap/BaseMap";
import stackImg1 from "../../assets/images/aboutImg.jpg";
import stackImg1mob from "../../assets/images/aboutImgMobile.jpg";
import pulse from "../../assets/images/pulseBg.png";
const classes = require('./About.module.scss');
const plaxContent = (
  <div>
    <h1>A Trusted Provider of First-Class Emergency Medical Care</h1>
    <h3>Since 1987</h3>
    <p>Integrity &middot; Modesty &middot; Accountability &middot; Respect &middot; Compassion</p>
  </div>
);

const about = (props) => {
  return (
    <div className={classes.AboutPage}>
      <Helmet>
        <title>Emergency Medical Air Transport | Medical Air Rescue Company</title>
        <meta name="description" content="Medical Air Rescue Company strives to deliver the best patient and provider care in medical air transport. We offer 24/7 rapid response throughout the Continental U.S." />
      </Helmet>

      <SkewPlax content={plaxContent} spClass="tall" addClass="About" extrasClass="StatBoxes" />

      {/* "Randy Springfield - Director of Choctaw EMS - presenting the A.L. Fountain Award - outstanding COVID-19 service. March 2021.  
      Shon Ginnis, PMD, Taylor Sullins, RN, BSN, and Martin Young, Pilot -  Hugo.  Reception hosted by City of Hugo for first responders.
      MARC’s Hugo crew was awarded at the Chamber of Commerce Banquet for their great work during the COVID crisis last year.  The award is named after Dr. Fountain, a local physician in the area." */}

      <div className={[classes.StackLayout, classes.About].join(' ')}>
        <div className={[classes.scRow, classes.scStory].join(' ')}>
          <div className={[classes.medDef, classes.storyWrap].join(' ')}>
            <div className={classes.storyText}>
              <p className={classes.title}><span>Medical Air Rescue Company</span><br/>A Family Who Serves Your Family</p>
              <div className={classes.textbox}>
                <p>
                  {/* Medical Air Rescue Company (MARC) is  */}
                  We are a family owned and operated business with a commitment to providing 
                  <span className={classes.buff}> extraordinary medical services,</span> and <span className={classes.buff}>unparalleled safety</span> in air medical 
                  transport. We assist critically ill and injured patients <b>throughout the continental U.S.</b>
                </p> 
                <p>
                  Our mission is to serve our communities with friendly, efficient, and trustworthy air medical care, 
                  and we have been dedicated to that mission for almost 40 years. 
                </p>
                <p><Link to="/contact" className={classes.textLink}>Contact us</Link> to learn more about what partnering with us can do for your organization.</p>
              </div>
            </div>
            
            <div className={classes.imgBar}>
              <img src={stackImg1} alt="Medical Air Rescue Company medical team providing emergency air medical transport" className={[classes.storyImage, classes.fw].join(' ')}/>
              <img src={stackImg1mob} alt="MARC crew treating critical, emergency transport patient" className={[classes.storyImage, classes.siMobile].join(' ')}/>
            </div>
          </div>
          <div id="advantages"></div>
        </div>
        
        <DetailTabs where="about" activeTab={props.location.activeTab} classless="aboutTabs" />
        
        <div className={[classes.scRow, classes.scStoryRev, classes.scBefore].join(' ')}>
          <div className={[classes.inside].join(' ')}>
            <div className={classes.storyText}>
              <div className={classes.review}>
                <p>“Huge Thank You to Pilot Andy and the crew for safely getting my dad closer to home for recovery. 
                They graciously let me hop a ride too. These guys made a scary and uncertain time much easier!”
                <br/><span className={classes.name}>-Brandy</span></p>
              </div>
            </div>
            <div className={classes.ilMap}><BaseMapLg /></div>
            {/* <div className={classes.ilMapMobile}><BaseMapSm /></div> */}
          </div>
        </div>
      </div>

      <div id="patient-focused" className={[classes.FlyerLayout, classes.AboutFly].join(' ')} data-uk-parallax="bgy: 250">
        <div className={classes.medDef}>
          <div className={[classes.boxXl, classes.c].join(' ')}><p className={classes.sub2}>Compassionate healthcare when it matters most</p></div>
          
          <div className={[classes.flContent, classes.row2mm].join(' ')}>
            <div className={[classes.boxLg, classes.left].join(' ')}>
              {/* <p className={classes.title}>Medical Air Rescue Company <span>does not</span> bill patients into bankruptcy with <span>unnecessary</span> and <span>devastating medical bills.</span></p> */}
              <p className={classes.title}>At Medical Air Rescue Company, we <span>do not</span> bill patients into bankruptcy with <span>unnecessary</span> and <span>devastating medical bills.</span></p>
            </div>

            <div className={[classes.boxSm, classes.right].join(' ')}>
              <ul>
                <li>We are <span>focused on our patients.</span> We have <span>invested millions</span> in patient care.</li>

                <li>We are <span>more cost-effective</span> than our competitors. Our rates are one-third to one-half the fee of other major flight programs.</li>
                
                <li><span>We work with families.</span> We have positioned ourselves to be an in-network provider with health insurance companies,
                  and <span>we are covered by most insurance.</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      <div className={[classes.HistorySec, classes.HistoryInline].join(' ')}>
        <div className={classes.titleRow}>
          <p className={classes.h3}>Medical Air Rescue Company<br/><span>A Proud History</span></p>
        </div>

        <div className={classes.hsWrap}>
          <div className={[classes.hsRow, classes.hsTop].join(' ')}>
            <div className={classes.medDef}>
              <div className={[classes.hsItem, classes.smItem, classes.hsi1].join(' ')}>
                <p className={classes.year}>1982</p>
                <p>The Dale family begins providing aviation services in Rapid City, South Dakota.</p>
              </div>
              
              <div className={[classes.hsItem, classes.lgItem, classes.hsi2].join(' ')} tabIndex="-1" data-uk-slider="autoplay:false;autoplay-interval:5000;">
                <div className="uk-position-relative">
                  <p className={classes.year}>1987</p>
                  <ul className={[classes.yearSlide, "uk-slider-items"].join(' ')}>
                    <li>
                      <p className={classes.bmargin05}>
                        Dale Aviation purchases Medical Air Rescue Company, quickly establishing a reputation as
                        the region’s premier air medical transport service.
                      </p>
                      <Link to="/rapid-city-south-dakota" className={classes.textLink}>View Base</Link>
                    </li>

                    <li>
                      <p>Company President, Jerry Dale, pioneers the development of the aircraft medical bed, and forms LifePort™ company.</p>
                    </li>
                  </ul>
                  <ul className={[classes.dotList, "uk-slider-nav uk-dotnav uk-flex-center uk-margin"].join(' ')}></ul>
                </div>
              </div>

              <div className={[classes.hsItem, classes.smItem, classes.hsi3].join(' ')}>
                <p className={classes.year}>2011</p>
                <p className={classes.bmargin05}>MARC opens location in <br/>Valentine, Nebraska.</p>
                <Link to="/valentine-nebraska" className={classes.textLink}>View Base</Link>
              </div>
            </div>
          </div>

          <div className={[classes.hsRow, classes.hsImg].join(' ')}><div className={classes.medDef}><img src={pulse} alt="Pulse/heartbeat" /></div></div>

          <div className={[classes.hsRow, classes.hsBtm].join(' ')}>
            <div className={classes.med2Def}>
              <div className={[classes.hsItem, classes.smItem, classes.hsi4].join(' ')} tabIndex="-1" data-uk-slider="autoplay:false;autoplay-interval:5000;">
                <div className="uk-position-relative">
                  <p className={classes.year}>2018</p>
                  <ul className={[classes.yearSlide, "uk-slider-items"].join(' ')}>
                    <li>
                      <p className={classes.bmargin05}>MARC opens location in <br/>McKinney, Texas.</p>
                      <Link to="/mckinney-texas" className={classes.textLink}>View Base</Link>
                    </li>

                    <li>
                    <p><b>emsCharts Award:</b><br/>2018 Fixed Wing Transports</p>
                    </li>
                  </ul>
                  <ul className={[classes.dotList, "uk-slider-nav uk-dotnav uk-flex-center uk-margin"].join(' ')}></ul>
                </div>
              </div>

              <div className={[classes.hsItem, classes.smItem, classes.hsi5].join(' ')}>
                <p className={classes.year}>2020</p>
                <p className={classes.bmargin05}>MARC opens location in <br/>Hugo, Oklahoma.</p>
                <Link to="/hugo-oklahoma" className={classes.textLink}>View Base</Link>
              </div>

              <div className={[classes.hsItem, classes.XXlgItem, classes.hsiLast].join(' ')} tabIndex="-1" data-uk-slider="autoplay:false;autoplay-interval:5000;">
                <div className="uk-position-relative">
                  <p className={classes.year}>2021</p>
                  <ul className={[classes.yearSlide, "uk-slider-items"].join(' ')}>
                    <li><p><b>A. L. Fountain Award: </b><br/>Presented in honor of all front line heroes in support of health during the COVID-19 pandemic.</p></li>
                  </ul>
                  {/* <ul className={[classes.dotList, "uk-slider-nav uk-dotnav uk-flex-center uk-margin"].join(' ')}></ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default about;
import React from "react";
import { Helmet } from "react-helmet";
import SkewBg from "../../components/SkewBg/SkewBg";
import SkewPlax from "../../components/SkewPlax/SkewPlax";
import image2 from "../../assets/images/marc13.jpg";
const classes = require('./Leadership.module.scss');

const skewTitle = (<h1>A Commitment to Excellence<br/>That Starts From the Top</h1>);
const subtitle = (<h2>Company Leadership</h2>)

const plaxContent= (
  <div className={[classes.plLeads].join(' ')}>
    <div className={[classes.top].join(' ')}>
      <div className={classes.plItem}><p className={classes.name}>Jerry Dale</p><p className={classes.title}>President/Owner</p></div>
      <div className={classes.plItem}><p className={classes.name}>Steve Dale</p><p className={classes.title}>CEO/Owner</p></div>
    </div>

    <div className={[classes.two].join(' ')}>
      <div className={[classes.group].join(' ')}>
        <div className={classes.plItem}><p className={classes.name}>Dustin Hunsaker</p><p className={classes.title}>Director of Operations</p></div>
        <div className={classes.plItem}><p className={classes.name}>Ken Maraman</p><p className={classes.title}>Director Medical Operations</p></div>
        <div className={classes.plItem}><p className={classes.name}>Trevor Puckett</p><p className={classes.title}>Director Maintenance</p></div>
      </div>
      
      <div className={[classes.group].join(' ')}>
        <div className={classes.plItem}><p className={classes.name}>Georgia Dale</p><p className={classes.title}>Director Administrative Services</p></div>    
        <div className={classes.plItem}><p className={classes.name}>Chad Carda, MD, ACEP</p><p className={classes.title}>Medical Director</p></div>
      </div>
    </div>
  </div>
);

const leadership = () => {
  return (
    <div className={classes.LeadershipPage}>
      <Helmet>
        <title>Medical Air Rescue Company Leadership & Staff</title>
        <meta name="description" content="Our leadership team is a large part of why Medical Air Rescue Company has been a trusted provider of first-class, emergency medical care for almost 40 years." />
      </Helmet>

      <SkewBg title={skewTitle} subtitle={subtitle} addClass="Leadership" />

      <SkewPlax content={plaxContent} addClass="Leadership" Xwrap="x" />
      <div className={[classes.medDef, classes.lrmed].join(' ')}>
        <div className={[classes.leadRow, classes.three].join(' ')}>
          <div className={[classes.image, classes.image2].join(' ')}><img src={image2} alt="" /></div>

          <div className={[classes.itemGroup, classes.ig2].join(' ')}>
            <div className={classes.item}><p className={classes.name}>Colby Bettles <span className={classes.title}> <span>...</span> Compliance Officer</span></p></div>
            <div className={classes.item}><p className={classes.name}>Derek "Levi" Leivestad <span className={classes.title}> <span>...</span> Chief Pilot</span></p></div>
            <div className={classes.item}><p className={classes.name}>Angela Hall <span className={classes.title}> <span>...</span> Communication Specialist/Owner AIREMS</span></p></div>
            
            <div className={classes.item}><p className={classes.name}>Tanner Thorfinnson <span className={classes.title}> <span>...</span> Base Lead - Rapid City, SD</span></p></div>
            <div className={classes.item}><p className={classes.name}>Chris Warnock <span className={classes.title}> <span>...</span> Base Lead - McKinney, TX</span></p></div>
            <div className={classes.item}><p className={classes.name}>Shon Maloy <span className={classes.title}> <span>...</span> Base Lead - Hugo, OK</span></p></div>
            <div className={classes.item}><p className={classes.name}>Tammy Gibson <span className={classes.title}> <span>...</span> Business Development/Account Manager</span></p></div>
            <div className={classes.item}><p className={classes.name}>Patricia Bettles <span className={classes.title}> <span>...</span> Chief Financial Officer</span></p></div>
            <div className={classes.item}><p className={classes.name}>Lindsay Schumacher <span className={classes.title}> <span>...</span> Business Marketing Consultant</span></p></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default leadership;
import React, {Component} from "react";
import classes from './FeedbackForm.module.scss';
// import { useForm, ValidationError } from '@formspree/react';

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;
    return (
      <div className={[classes.cFormWrap, classes[this.props.FormType]].join(' ')}>
        <form
          className={classes.feedbackForm}
          onSubmit={this.submitForm}
          action="https://formspree.io/f/mwkybygn"
          method="POST"
        >          
          <h3>Share Your Feedback</h3>
         
          <input type="hidden" name="_subject" id="email-subject" value="Feedback Form Submission"></input>
          <div className={[classes.formGroup, classes.fgname].join(' ')}>
            <label for="Name">Name</label>
            <input className={classes.cfield} type="text" name="name" id="Name" />
          </div>
            
          <div className={[classes.formGroup, classes.fgemail].join(' ')}>
            <label for="Email">Email</label>
            <input className={classes.cfield} type="email" name="_replyto" id="Email" />
          </div>

          <div className={[classes.formGroup, classes.fgaffil].join(' ')}>
            <label for="affiliation">Affiliation</label>
            <select name="affiliation" id="affiliation">
              <option value="" selected="" disabled="">Select</option>
              <option value="Medical Organization">Medical Organization</option>
              <option value="Patient">Patient</option>
              <option value="Patient Family Member">Patient Family Member</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div className={[classes.formGroup, classes.fgcrew].join(' ')}>
            <label for="rateCrew">Please rate the courtesy and professionalism of the crew.</label>
            <select name="rateCrew" id="rateCrew">
              <option value="" selected="" disabled="">Select</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>

          <div className={[classes.formGroup, classes.fgcomp].join(' ')}>
            <label for="rateSatisfaction">Please rate your overall satisfaction with MARC.</label>
            <select name="rateSatisfaction" id="rateSatisfaction">
              <option value="" selected="" disabled="">Select</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>

          <div className={[classes.formGroup, classes.fgcomments].join(' ')}>
            <label for="comments">Additional Comments</label>
            <textarea className={classes.cfield} name="comments" id="comments"></textarea>
          </div>

          <div className={[classes.formGroup, classes.fgflight, classes.final].join(' ')}>
            <label for="flightDetails">Flight Number and/or Crew Names (if known)</label>
            <textarea className={classes.cfield} name="flightDetails" id="flightDetails"></textarea>
          </div>
          
          {status === "SUCCESS" ? <p className={classes.successSub}>Thanks for reaching out. Your opinion is very important to us.</p> : <div className={[classes.btnRow].join(' ')}><button className={[classes.marcLink, classes.submit].join(' ')}>Submit</button></div>}
          {status === "ERROR" && <p className={classes.errorSub}>Your submission contains here was an error. Please check your information, and try again.</p>}
        </form>
      </div>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}


// function FeedbackForm() {
//   const [state, handleSubmit] = useForm("mwkybygn");
//   if (state.succeeded) {
//       return <p>Thanks for joining!</p>;
//   }
//   return (
//       <form onSubmit={handleSubmit}>
//       <label htmlFor="email">
//         Email Address
//       </label>
//       <input
//         id="email"
//         type="email" 
//         name="email"
//       />
//       <ValidationError 
//         prefix="Email" 
//         field="email"
//         errors={state.errors}
//       />
//       <textarea
//         id="message"
//         name="message"
//       />
//       <ValidationError 
//         prefix="Message" 
//         field="message"
//         errors={state.errors}
//       />
//       <button type="submit" disabled={state.submitting}>
//         Submit
//       </button>
//     </form>
//   );
// }
// function App() {
//   return (
//     <FeedbackForm />
//   );
// }
// export default App;

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SkewBg from "../../components/SkewBg/SkewBg";
import classes from "./Article.module.scss";

class Article extends Component {
  render() {
    const skewTitle = (<h1>{this.props.title}</h1>);
    const skewText = (
      <Link to="/marc-news"><span uk-icon="icon: arrow-left"></span> Back to all news</Link>
    );
    const lay = this.props.image ? "withImg" : "solo";





    // function ellipsify (str) {
    //   if (str.length > 10) {
    //     return (str.substring(0, 10) + "...");
    //   }
    //   else {
    //     return str;
    //   }
    // }
    // var fullContent = this.props.story; //"This is my full content sentence, that is not a full page of text, but who knows what will happen?"; 
    // var desc = ellipsify(fullContent);





    return (
      <div className={[classes.ArticlePage, classes["item" + this.props.id], classes[lay]].join(' ')}>
        <Helmet>
          <title>{this.props.title}</title>
          <meta name="description" content="Read this article to learn more about how Medical Air Rescue Company gives back to the communities we serve." />
        </Helmet>

        <SkewBg title={skewTitle} addClass="ArticlePageTitle" text={skewText} />

        {/* <h2>{desc}</h2> */}
        
        <div className={classes.ArticleBody} id="fix-wrap" data-uk-grid>
          <div className={[classes.insideAltX, classes.abWrap].join(' ')}>
            {/* {this.props.image ? <div className={classes.articleImage}><img src={this.props.image} alt={this.props.title} className={classes.imageArticle} /></div> : null} */}
            {this.props.image ? 
              <div className={classes.articleImage}>
                <img src={this.props.image} alt={this.props.title} className={[classes.imgResponsive, classes.imgRel].join(' ')} />
                <div className={classes.aiStick} data-uk-sticky="offset:30; bottom: #fix-wrap;"><img src={this.props.image} alt={this.props.title} className={classes.imageArticle} /></div>
              </div> 
            : null}
            {this.props.story ? <div className={classes.articleStory}>{this.props.story}</div> : null}

            {/* <div className={classes.closing}><p>### About Medical Air Rescue: Medical Air Rescue Company (MARC) has been a trusted provider of first-class emergency medical services for nearly 40 years and is proud to serve patients throughout South Dakota, Nebraska, Texas, and Oklahoma. All of MARC’s ground and air ambulances carry the latest in life-saving technology and are staffed by experienced EMTs, Paramedics, Registered Nurses, and Pilots rigorously trained in their field of expertise. You can reach MARC’S communications center 24/7 to arrange transportation with an experienced flight coordinator by calling (605) 393-0000. For more information, please visit MedicalAirRescue.com.</p></div> */}
          </div>
        </div>
      </div>
    );
  }
}
export default Article;
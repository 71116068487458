import React from "react";
const classes = require('./Social.module.scss');

const social = (props) => {
  return (
    <ul className={[classes.Social, classes[props.where]].join(' ')}>
      <li><a href="https://www.facebook.com/medicalairrescue" target="_blank" rel="noopener noreferrer">
        <span className={classes.SocialIcon} aria-label="Medical Air Rescue Company Facebook Link" uk-icon="icon: facebook"></span>
      </a></li>

      <li><a href="https://www.instagram.com/medicalairrescue/" target="_blank" rel="noopener noreferrer">
        <span aria-label="Medical Air Rescue Company Instagram Link" uk-icon="icon: instagram"></span>
      </a></li>

      <li><a href="https://twitter.com/medicalairrescu" target="_blank" rel="noopener noreferrer">
        <span aria-label="Medical Air Rescue Company Twitter Link" uk-icon="icon: twitter"></span>
      </a></li>

      <li><a href="https://www.linkedin.com/company/medical-air-rescue-co/" target="_blank" rel="noopener noreferrer">
        <span aria-label="Medical Air Rescue Company LinkedIn" uk-icon="icon: linkedin"></span>
      </a></li>
    </ul>
  );
};

export default social;

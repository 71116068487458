import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SkewBg from "../../components/SkewBg/SkewBg";
import classes from "./News.module.scss";
import defaultBg from "../../assets/images/flyerBg.jpg";
import newsItems from '../../components/ArticleLoop/ArticleLoop';
const skewTitle = (<h1>In the News</h1>);
const subtitle = (<h2>Medical Air<br/> Rescue Company</h2>);

class News extends Component {
  render() {
    const newsLoop = newsItems.map((item) =>
      <a href={item.link} key={item.id} className={[classes.newsItem, classes["item" + item.id]].join(' ')} style={{ backgroundImage: "url(" + [item.image ? item.image : defaultBg]  + ")" }}>
        <span className={[classes.newsTitle].join(' ')}>{item.title}</span>
      </a>
    );
    
    return (
      <div className={classes.NewsPage}>
        <Helmet>
          <title>Community Outreach | Medical Air Rescue Company</title>
          <meta name="description" content="Medical Air Rescue Company stays actively involved in our local communities. We believe in giving back through education and philanthropic initiatives." />
        </Helmet>

        <SkewBg title={skewTitle} stackSub={subtitle} addClass="SkewStack2" />

        <div className={[classes.PageIntroNews2].join(' ')}>
          <div className={classes.medDef}>
            <div className={classes.piBody}>
              <div className={[classes.box, classes.box1].join(' ')}>
                <p>Our mission is to serve our communities with friendly, efficient, and trustworthy air medical care. 
                  Our entire organization believes in giving back through education, tailored services, and philanthropic initiatives, 
                  and we love being actively involved in our local communities. 
                  Read through our press releases, or <Link to={{pathname:"/about#advantages", activeTab: "partner"}} className={classes.textLink}>click here </Link> 
                  for more examples of how we accomplish our mission.
                </p>
              </div>
            </div>
          </div>
        </div>
        
        <div className={classes.newsWrap}>
          <div className={classes.insideN}>
            {newsLoop}

            <div className={[classes.newsItem, classes.niCta2, classes.ctaFull].join(' ')}>
              <div className={classes.inner}>
                <p>If you are interested in a partnership, or you would like more information about the benefits of working with us, follow the link below to contact us.
                We would love to be a part of your community.</p>
                <Link to="/contact" className={classes.marcLinkRev}>Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default News;
import React from "react";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
import classes from "./Feedback.module.scss";
import SkewBg from "../../components/SkewBg/SkewBg";
import FeedbackForm from "../../components/FeedbackForm/FeedbackForm";
import FeedbackImg from "../../assets/images/uniform.jpg"

const skewTitle = (<h1>Medical Air<br/> Rescue Company</h1>);
const skewSub = (<h2>Give Us Your Feedback</h2>);


const feedback = () => {
  return (
    <div className={classes.FeedbackPage}>
      <Helmet>
        <title>Give Medical Air Rescue Company Your Feedback</title>
        <meta name="description" content="MARC provides emergency air medical transport throughout the continental U.S. We are committed to building on our success, and we'd love your feedback." />
      </Helmet>

      <SkewBg title={skewTitle} subtitle={skewSub} />

      <div className={classes.FeedbackBody}>
        <div className={classes.fbIntro}>
          <div className={classes.medDef}>
            <p>We value each partnership, patient, and family member, and believe that continuous evaluation of our procedures is key. Our goal is to 
              constantly monitor patient and facility satisfaction, so that we can continue to be the premier air medical provider. Use 
              the <a href="#feedbackForm" data-uk-scroll className={classes.textLinkOg}>form below</a> to share your 
              experience with us, or leave a review for one of our bases.
            </p>

            <div className={classes.linkRow}>
              <a href="https://g.page/r/CcxpuaMWJSVTEAg/review" target="_blank" rel="noreferrer" className={classes.textLink}>Rapid City, SD<br/><span>Leave a Review</span></a>
              <a href="https://g.page/r/CQhoe-T9d51wEAg/review" target="_blank" rel="noreferrer" className={classes.textLink}>McKinney, TX<br/><span>Leave a Review</span></a>
              <a href="https://g.page/r/CciVvJtDycozEAg/review" target="_blank" rel="noreferrer" className={classes.textLink}>Valentine, NE<br/><span>Leave a Review</span></a>
              <a href="https://www.google.com/maps/place/Medical+Air+Rescue+Co+-+Hugo/@34.017884,-95.5434389,13z/data=!4m5!3m4!1s0xa876a84efee56acb:0x9a8e3e833169702d!8m2!3d34.017889!4d-95.5084194" target="_blank" rel="noreferrer" className={classes.textLink}>Hugo, OK<br/><span>Leave a Review</span></a>
            </div>
          </div>
        </div>

        <div className={classes.medDef}>
          <img src={FeedbackImg} className={classes.formImg} alt="Medical Air Rescue Company transporting patient to emergency flight." />
          <div id="feedbackForm" className={classes.ilForm}><FeedbackForm FormType="FeedbackPg" /></div>
        </div>
      </div>
    </div>
  );
}
export default feedback;
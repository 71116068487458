import React, {Component} from 'react';
import { Route, Switch } from "react-router-dom";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import classes from './App.module.scss';
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from './hoc/Home/Home';
import About from './hoc/About/About';
import Fleet from './hoc/Fleet/Fleet';
import Leadership from './hoc/Leadership/Leadership';
import Employment from './hoc/Employment/Employment';
import Contact from './hoc/Contact/Contact';
import RapidCity from './hoc/Locations/RapidCity';
import Valentine from './hoc/Locations/Valentine';
import McKinney from './hoc/Locations/McKinney';
import Hugo from './hoc/Locations/Hugo';
import News from './hoc/News/News';
import EmployeeLinks from './hoc/EmployeeLinks/EmployeeLinks';
import Fallback from './hoc/Fallback/Fallback';
import Feedback from './hoc/Feedback/Feedback';
import MedicalForms from './hoc/MedicalForms/MedicalForms';
import Phone from "./components/Shared/Phone";
import newsItems from './components/ArticleLoop/ArticleLoop';
import Article from './hoc/NewsArticle/Article';

class App extends Component {
  render() {
    const articleLoop = newsItems.map((item) =>
      <Route
        path={"/" + [item.link]}
        key={item.id}
        render={props => <Article {...props} id={item.id} link={item.link} title={item.title} story={item.story} image={item.image} />}
      />
    );

    let routes = (
      <Switch>
        <Route exact={true} path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/about#:tab" component={About} />
        <Route path="/fleet" component={Fleet} />
        <Route path="/employment" component={Employment} />
        <Route path="/leadership" component={Leadership} />
        <Route path="/marc-news" component={News} />
        <Route path="/contact" component={Contact} />
        <Route path="/rapid-city-south-dakota" component={RapidCity} />
        <Route path="/valentine-nebraska" component={Valentine} />
        <Route path="/mckinney-texas" component={McKinney} />
        <Route path="/hugo-oklahoma" component={Hugo} />
        <Route path="/employee-links" component={EmployeeLinks} />
        <Route path="/medical-forms" component={MedicalForms} />
        <Route path="/feedback" component={Feedback} />
        {articleLoop}
        <Route component={Fallback} />
      </Switch>
    );
    
    const pathName = window.location.pathname.replace('-','');
    const wrapClass = pathName.replace('/', '');

    return (
      <div className={[classes.marcWrap, classes[wrapClass]].join(' ')}>
        <Header />
        <main className={classes.Content}>
          {routes}
        </main>

        <Footer />
        <ScrollUpButton
          ContainerClassName={classes.ToTopBtn}
          TransitionClassName="MyOverRideTransitionedClass"
          style={{ fill: 'rgb(255,255,255)', backgroundColor: '#000', padding: '0px 5px', bottom: '15px' }}
          EasingType='linear'
        />

        <div id="schedule-flight-modal" className={[classes.modalFull, "uk-flex-top"].join(' ')} data-uk-modal>
          <div className={[classes.modalDialog, "uk-modal-dialog uk-modal-body uk-margin-auto-vertical"].join(' ')}>
            <button className={[classes.closeIt, "uk-modal-close-default"].join(' ')} type="button" data-uk-close></button>
            <div className={[classes.modalCol, classes.mc1].join(' ')}>
              <p className={classes.h3}>Is This an <span>Emergency?</span></p>
              <div className={classes.titleLine}/><div className={classes.clearfix}/>
              <p className={classes.p1}>Call our 24/7 emergency dispatch line for immediate response:</p> <p><Phone where="modal" /></p>
            </div>

            <div className={[classes.modalCol, classes.mc2].join(' ')}>
              <p className={classes.h4}>Pre-Schedule a <br/><span>Non-Emergent</span> Flight</p>
              <hr className={classes.sep}/><div className={classes.clearfix}/>
              <p className={classes.preCta}>Contact Our Communication Center<br className={classes.maybe} /> for Request:<br className={classes.resp}/><Phone where="modal" /></p>
              <hr className={classes.sep}/><div className={classes.clearfix}/>

              <p>We take pride in providing immediate solutions for patients in self-pay transport situations (those determined not to be covered by insurance). We
                offer live, around the clock contact with our billing department.</p>
              <p className={classes.bmargin0}>We have multiple, flexible payment options for pre-scheduled and self-pay transports, and strive to personally work with each patient’s financial situation and logistics needs.</p>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default App;

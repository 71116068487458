import React from "react";
import { Helmet } from "react-helmet";
import SkewPlax from "../../components/SkewPlax/SkewPlax";
import SkewBg from "../../components/SkewBg/SkewBg";
import DetailTabs from "../../components/Stack/DetailTabs";
import BaseInfo from "../../components/BaseInfo/BaseInfo";
import baseImage from "../../assets/images/rapidcity.jpg";
import Welcome from "./Welcome.js";
const classes = require('./Locations.module.scss');
const base = "Rapid City, South Dakota";
const baseShort = "Rapid City, SD";
const plaxContent = (<Welcome />);
const skewSub = (<h3>{base}</h3>);
const skewTitle1 = (<h4>Medical Air<br/> Rescue Company</h4>);
const skewText = (<h5>Corporate Headquarters</h5>);
const baseInfo = (
  <div>
    <p><b>Base Lead</b><br/>Tanner Thorfinnson, BSN, RN, CFRN</p>
    <p><b>Lead Pilot</b><br/>Derek "Levi" Leivestad</p>
    <div className={classes.clearfixBtn}/>
    <a 
      href="https://g.page/r/CcxpuaMWJSVTEAg/review" 
      target="_blank" 
      rel="noreferrer"
      className={classes.marcLinkSm}>Leave a Review</a>
  </div>
);

const rapidCity = () => {
  return (
    <div className={[classes.LocationsPage, classes.Dakota].join(' ')}>
      <Helmet>
        <title>{baseShort} Base | Medical Air Rescue Company</title>
        <meta name="description" content="Medical Air Rescue Company provides safe, reliable medical air transport across the contenintal U.S. We also love serving our local community in Rapid City, SD." />
      </Helmet>

      <SkewPlax content={plaxContent} spClass="tall" addClass="Bases" />
      <SkewBg skewStyle="BaseStack" title={skewTitle1} stackSub={skewSub} addClass="rapStack" text={skewText} />
      <BaseInfo
        baseClass="Dakota"
        base={base}
        baseDetails={baseInfo}
        image={baseImage}
      />    

      <DetailTabs where="bases" classless="baseTabs" headline="One Life. One Mission. Every Time." />
    </div>
  );
}
export default rapidCity;
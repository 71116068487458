import React from "react";
import Phone from "../../components/Shared/Phone";
import Email from "../../components/Shared/Email";
const classes = require('./SkewPlax.module.scss');

const skewPlax = (props) => {
  return (
    <div className={[classes.SkewPlax, classes[props.spClass], classes[props.addClass]].join(' ')} data-uk-parallax="bgy: 250; media:767;">
      <div className={classes.spWrap}>
        <div className={classes[props.wrap]}>
          <div className={classes.textBox}>
            {props.content}
          </div>

          {props.addClass === "Bases" ? <div className={classes[props.extrasClass]}><p>Dispatch: <Phone where="baseHeader" /></p><p><Email where="baseHeader" /></p></div> : null} 
          {props.extras ? <div className={classes[props.extrasClass]}>{props.extras}</div> : null} 
        </div>
      </div>
      
      {props.extras2 ? <div className={classes[props.extras2Class]}>{props.extras2}</div> : null}
      <div className={classes.clearfix}/>
    </div>
  );
};
skewPlax.defaultProps = {
  spClass: "default",
  wrap: "med3Def",
  extrasClass: "cta",
  extras2Class: "quote"
};
export default skewPlax;
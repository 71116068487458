import React, {Component} from "react";
const classes = require('./ContactForm.module.scss');
// const styles = require('../../hoc/Contact/Contact.module.scss');
// action="https://formspree.io/f/mzbyropv"

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;
    let styleParent = classes;
    // if (this.props.FormType === "ContactPage") {
    //   styleParent = styles;
    // }

    return (
      <div className={[styleParent.cFormWrap, styleParent[this.props.FormType]].join(' ')}>
        <form
          className={styleParent.contactForm}
          onSubmit={this.submitForm}
          action="https://formspree.io/f/xknkqzdd"
          method="POST"
        >
          <div className={[styleParent.formGroup, styleParent.fgname].join(' ')}>
            <label>Full Name</label>
            <input className={styleParent.cfield} type="text" name="name" />
          </div>
            
          <div className={[styleParent.formGroup, styleParent.fgemail].join(' ')}>
            <label>Email</label>
            <input className={styleParent.cfield} type="email" name="_replyto" />
          </div>

          <div className={[styleParent.formGroup, styleParent.fgphone].join(' ')}>
            <label>Phone</label>
            <input className={styleParent.cfield} type="text" name="phone" />
          </div>

          <div className={[styleParent.formGroup, styleParent.fgcompany].join(' ')}>
            <label>Company</label>
            <input className={styleParent.cfield} type="text" name="company" />
          </div>

          <div className={[styleParent.formGroup, styleParent.fgdets].join(' ')}>
            <label>Message</label>
            <textarea className={styleParent.cfield} name="details"></textarea>
          </div>
          
          {status === "SUCCESS" ? <p className={classes.successSub}>Thanks for reaching out. A member of our team will contact you soon.</p> : <div className={[styleParent.btnRow].join(' ')}><button className={[styleParent.marcLink, styleParent.submit].join(' ')}>Submit</button></div>}
          {status === "ERROR" && <p className={classes.errorSub}>Your submission contains here was an error. Please check your information, and try again.</p>}
        </form>
      </div>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}
// Form.defaultProps = {
//   FormType: 'ContactPage'
// };

// export default Form;

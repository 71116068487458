import story1img from "../../assets/images/news/newsSummerNights.jpg"
import story2img from "../../assets/images/news/newsHonored.png"
import story3img from "../../assets/images/news/newsNurses.png"
import story4img from "../../assets/images/news/newsTraining.png"
import story5img from "../../assets/images/news/newsSafety.png"

const story1 = (
  <div>
    <p><b>July 2, 2021 -</b> Medical Air Rescue Company (MARC) proudly sponsored Week 5 of Rapid City's Summer Nights event series and turned it into an event to celebrate First Responders. MARC invited all local fire, police, hospital and EMS personnel to the popular event where modern rock band TripWire preformed for the crowd.</p>
    <p>MARC also raffled off a 30-minute flight of Black Hills to raise money for Mission 22, a non-profit organization dedicated to healing America's veterans. <a href="https://mission22.com/about" target="_blank" rel="noreferrer">Mission 22 </a>supports the veteran community with three main programs; veteran treatment programs, memorials and community social impact. Mission 22 provides treatment programs to veterans for Post-Traumatic Stress, Traumatic Brain Injury and other issues they might be facing.</p>
    <p>Summer Nights is a weekly concert series that runs every Thursday evening from Memorial Day to Labor Day and is a FREE event for all ages. The event occurs every Thursday during the Summer with Happy Hour running from 5:00pm - 6:00pm and the entertainment from 6:00pm - 9:00pm in the heart of downtown Rapid City, SD. For more information visit <a href="http://rapidcitysummernights.com" target="_blank" rel="noreferrer">rapidcitysummernights.com</a></p>
  </div>
  )
const story2 = (
  <div>
    <p><b>May 10, 2021 (Hugo, Oklahoma) - </b> Medical Air Rescue Company (MARC), a provider of first-class emergency medical services, was recently honored at the Hugo Chamber of Commerce Banquet with the A.L. Fountain Award for their exceptional work throughout the COVID-19 pandemic.</p>
    <p>Randy Springfield, Executive Director of the Choctaw County Ambulance Authority, presented MARC with the A.L. Fountain Award - named after Dr. Fountain, a local physician in the area. There to accept the award on behalf of MARC were members of the Hugo transport team: Shon Ginnis, PMD; Taylor Sullins, RN, BSN; and Martin Young, Pilot.</p>
    <p>When asked about the award, Sullins said, “It's really nice to be recognized by the community we serve. I am so proud of everyone at Medical Air Rescue for stepping up to meet the extra demands of COVID while maintaining our premier level of care for our patients.”</p>
    <p>Each nurse and medic on the MARC team is required to go through 672 hours of training and is rigorously trained in the latest procedures in trauma, cardiac, medical, and pediatric care. Every day, MARC's experienced team of pilots, paramedics, EMTs, registered nurses, and flight coordinators stand ready to safely transport loved ones in their greatest time of need.</p>
  </div>
)
const story3 = (
  <div>
    <p><b>May 5, 2021 (Rapid City, South Dakota) -</b> Medical Air Rescue Company (MARC) is proud to celebrate National Nurses Week, May 6 - 12, and honor the nurses that serve as both the frontline and backbone of the medical field.</p>
    <p>“Nurses perform some of the most challenging work, and we want to give a huge thank you to nurses everywhere,” said Ken Maraman, BS, RN, NREMT-P, CFRN, Director of Medical Operations at MARC. "We wouldn't be able to provide the very best care to our patients if it wasn't for the incredible and dedicated RN's we have on the MARC team.”</p>
    <p>MARC understands the necessity and importance of nurses in the medical field, which is why they have a Registered Nurse on every transport. Each RN is rigorously trained in the latest procedures in trauma, cardiac, medical, and pediatric care, so they can provide each and every patient with the specialized and individualized care they need.</p>
    <p>National Nurses Week begins each year on May 6th, National Nurses Day, and runs until May 12th, Florence Nightingale's birthday, the founder of modern nursing. May 8th is also National Student Nurses Day.</p>
  </div>
)
const story4 = (
  <div>
    <p><b>April 17, 2019 (Rapid City, South Dakota) -</b> Medical Air Rescue Company (MARC) joined other local EMS providers and personnel from Ellsworth Air Force Base to put on a one-of-a-kind training exercise for students at Western Dakota Tech. The scenario? An active shooter on campus with mass casualties.</p>
    <p>Fire science, paramedic, law enforcement, nursing, health information management, medical lab, phlebotomy, surgical technology, and dental students were represented in handling different aspects of the scenario.</p>
    <p>“As a first responder, I want to do everything I can to help train the next generation. I hope they never have to use this training, but if they do, they will be that much more prepared,” said Ken Maraman, Director of Medical Operations, Medical Air Rescue.</p>
    <p>“We've done several scenarios in the past where there's a car wreck, a fire, and a chemical exposure,” said Lloyd McNett, simulation director. “In seeing the nationwide epidemic with mass shooters and, granted we're in rural South Dakota, those types of events we have to have first responders who are prepared for those.”</p>
    <p>Law enforcement students arrived first and took down an acting gunman in a room with multiple injured victims. Soon after, a student ran into the room to alert law enforcement of another possible shooter on the second floor.</p>
    <p>Heading up the stairs, students found more acting victims. Inside a classroom immediately next to the stairs, students were fighting back against another shooter before law enforcement could intervene and subdue the situation.</p>
    <p>Once the students deemed the situation under control, medical response could move in and treat the acting victims. They were treated indoors and then carried outdoors to be taken by ambulance to the simulated hospital wing of the school.</p>
    <p>After the exercise students debriefed how they handled the scenario.</p>
  </div>
)
const story5 = (
  <div>
    <p><b>June 30, 2018 -</b> June marked National Safety Month, and Medical Air Rescue Company (MARC) celebrated by participating in community events all across the country to help educate individuals on ways to reduce the leading causes of unintentional injury and death at work, on the road, and in homes and communities.</p>
    <p>MARC prioritizes the safety of all crew, employees, and patients, which has led to their impeccable safety record - zero crashes and zero fatalities. No matter where you live - you can also participate in National Safety Month in June and all year long. The National Safety Council recommends some great ways to get involved:</p>
    <ul>
      <li>1. Prevent Incidents Before They Start: Identifying risks and taking proactive safety measures to reduce hazard exposure on important topics from ergonomics to chemical management is crucial to creating a safe workplace.</li>
      <li>2. It's Vital to Feel Safe on the Job: Being able to be one's self at work without fear of retaliation is necessary for an inclusive safety culture. Leading organizations focus not only on physical safety, but psychological safety as well.</li>
      <li>3. Advance Your Safety Journey: Safety is all about continuous improvement. Whether organizationally or individually, NSC can help provide guidance for your path forward.</li>
    </ul>
  </div>
)

const newsItems = [
  {id:1, title:"Medical Air Rescue Celebrates First Responders at Rapid City Summer Nights", image: [story1img], story: [story1], link:"MARC-Summer-Nights"},
  {id:2, title:"Medical Air Rescue Honored for Outstanding Service", image: [story2img], story: [story2], link:"Medical-Air-Rescue-Honored"},
  {id:3, title:"Medical Air Rescue Celebrates National Nurses Week", image: [story3img], story: [story3], link:"Medical-Air-Rescue-Company-Celebrates-National-Nurses-Week"},
  {id:4, title:"First Responders Train Students in Active Shooter Scenario", image: [story4img], story: [story4], link:"MARC-First-Responders-Train-Students2019"},
  {id:5, title:"Medical Air Rescue Celebrates National Safety Month", image: [story5img], story: [story5], link:"Medical-Air-Rescue-Company-National-Safety-Month"},
]
export default newsItems
import React from "react";
import { Helmet } from "react-helmet";
import SkewPlax from "../../components/SkewPlax/SkewPlax";
import SkewBg from "../../components/SkewBg/SkewBg";
import DetailTabs from "../../components/Stack/DetailTabs";
import BaseInfo from "../../components/BaseInfo/BaseInfo";
import baseImage from "../../assets/images/valentine.jpg";
import Welcome from "./Welcome.js";
const classes = require('./Locations.module.scss');
const plaxContent = (<Welcome />);
const skewSub = (<h3>Valentine, Nebraska</h3>);
const skewTitle1 = (<h4>Medical Air<br/> Rescue Company</h4>);
const baseShort = "Valentine, NE";
const baseInfo = (
  <div>
    <p><b>Base Lead</b><br/>Jason Robutz, LP, FP-C</p>
    <p><b>Lead Pilot</b><br/>AJ Abbott</p>
    <div className={classes.clearfixBtn}/>
    <a 
      href="https://g.page/r/CciVvJtDycozEAg/review" 
      target="_blank" 
      rel="noreferrer"
      className={classes.marcLinkSm}>
      Leave a Review</a>
  </div>
);

const valentine = () => {
  return (
    <div className={[classes.Locations, classes.ValentinePage].join(' ')}>
      <Helmet>
        <title>{baseShort} Base | Medical Air Rescue Company</title>
        <meta name="description" content="Medical Air Rescue Company provides safe, reliable medical air transport across the contenintal U.S. We also love serving our local community in Valentine, NE." />
      </Helmet>

      <SkewPlax content={plaxContent} spClass="tall" addClass="Bases" />
      <SkewBg skewStyle="BaseStack" title={skewTitle1} stackSub={skewSub} addClass="valStack" />
      <BaseInfo baseClass="Nebraska" base="Valentine, Nebraska" baseDetails={baseInfo} image={baseImage} />
      <DetailTabs where="bases" classless="baseTabs" headline="One Life. One Mission. Every Time." />
    </div>
  );
}
export default valentine;

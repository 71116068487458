import React from "react";
// import Phone from "../../components/Shared/Phone";
// import Email from "../../components/Shared/Email";
const classes = require('./SkewBg.module.scss');

const skewBg = (props) => {
  return (

    <div className={[classes.SkewBg, classes[props.skewStyle], classes[props.addClass]].join(' ')}>
      <div className={[classes[props.wrap], classes.textBox].join(' ')}>
        {props.stackSub ? props.stackSub : null}
        {props.title}
        {props.subtitle ? props.subtitle : null}
        {props.text ? props.text : null}
      </div>
      <div className={classes.clearfix}/>
    </div>   
  );
};
skewBg.defaultProps = {
  skewStyle: "SkewHd",
  wrap: "medDef"
};
export default skewBg;
import React, { useEffect } from "react";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import classes from "./MapShow.module.scss";

const MapShow = () => {
  mapboxgl.accessToken = 'pk.eyJ1Ijoia3BhcnJpcyIsImEiOiJjanVpczd5eGYxZHBmNDRwYjAyNnpncGFpIn0.H7V-1fyx94DiLTwsUqLV9g';
  useEffect(() => {
    var map = new mapboxgl.Map({
      container: "mapWrap",
      // style: 'mapbox://styles/mapbox/streets-v11', 
      style: 'mapbox://styles/kparris/ckr3m09d61mgc18o5k06ooijo',
      center: [-102.15, 43.9],
      maxZoom: 16,
      minZoom: 4,
      zoom: 4
    });

    var locations = [
      {
        'id': '2',
        'title': 'Rapid City, SD - Corporate Base',
        'description':"Rapid City, South Dakota - MARC Corporate Base",
        'camera': {
            center: [-103.15, 44.1],
            zoom: 12.21,
            pitch: 50
        }
      },
      {
        'id': '3',
        'title': 'Valentine, NE Base',
        'description':"Valentine, Nebraska Base",
        'camera': {
            center: [-100.55, 42.87],
            bearing: -8.9,
            zoom: 12.2
        }
      },
      {
        'id': '1',
        'title': 'McKinney, TX Base',
        'description':'McKinney, Texas Base',
        'camera': {
            center: [-96.64, 33.22],
            bearing: 25.3,
            zoom: 13.5
        }
      },
      {
        'id': '4',
        'title': 'Hugo, OK Base',
        'description':"Hugo, Oklahoma Base",
        'camera': {
            center: [-95.51, 34.01],
            bearing: 36,
            zoom: 12.5
        }
      }
    ];

    // function highlightBorough(code) {
    //   // Only show the polygon feature that cooresponds to `borocode` in the data
    //   map.setFilter('highlight', ['==', 'borocode', code]);
    // }

    function playback(index) {
      // highlightBorough(locations[index].id ? locations[index].id : '');

      // Animate the map position based on camera properties
      map.flyTo(locations[index].camera);

      map.once('moveend', function () {
        // Duration the slide is on screen after interaction
        window.setTimeout(function () {
          // Increment index
          index = index + 1 === locations.length ? 0 : index + 1;
          playback(index);
        }, 1000); // After callback, show the location for 5 seconds.
      });
    }

    map.on('load', function () {
      map.addSource('bases', {
        'type': 'vector',
        'url': 'mapbox://mapbox.8ibmsn6u'
        // 'url': 'kparris.ckqwt0b7t49u820lcoupb1ghr-1d01h'
      });
      // Start the playback animation for each borough
      playback(0);
    });
  }, []); // end

  return (
    <div className={classes.mapSec}>
      <div id="mapWrap" className={classes.map}></div>
    </div>
  );
};

export default MapShow;


